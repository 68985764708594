import React, { useEffect, useState } from "react";
import {
  Form as BootstrapForm,
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import logo from "../../static/logo.png";
import useAxios from "../../hooks/useAxios";
import * as Yup from "yup";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { signInWithPopup } from "firebase/auth";
import {
  auth,
  providerGoogle,
  providerMicrosoft,
  providerApple,
} from "../../firebase";
import { useForm } from "../../hooks/useForm";
import { message, Alert } from "antd";
import { Link } from "react-router-dom";
const RegisterSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const RegisterPage = () => {
  const [data, loading, loaded, error, request] = useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [authError, setAuthError] = useState(null);

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case "auth/email-already-in-use":
        return "The email address is already in use by another account.";
      case "auth/invalid-email":
        return "The email address is not valid.";
      case "auth/operation-not-allowed":
        return "Email/password accounts are not enabled.";
      case "auth/weak-password":
        return "The password is too weak.";
      default:
        return "The email address is already in use by another account.";
    }
  };

  useEffect(() => {
    const auth = window.localStorage.getItem("Authorization");
    if (auth) {
      window.location.href = "/";
    }
  }, []);
  const loginWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, providerGoogle);
      const idToken = await result.user.getIdToken();

      const response = await request("/user/register", {
        idToken,
        email: result.user.email,
        password: "",
        lastname: result.user.displayName.split(" ")[1],
        firstname: result.user.displayName.split(" ")[0],
        profile_pic: result.user.photoURL,
      });
      window.localStorage.setItem("token", response.token);
      window.location.assign("/");
    } catch (error) {
      setAuthError(getErrorMessage(error.code));
    }
  };
  const loginWithApple = async () => {
    try {
      const result = await signInWithPopup(auth, providerApple);
      const idToken = await result.user.getIdToken();

      const response = await request("/data", {
        idToken,
        email: result.user.email,
        password: "",
        lastname: result.user.displayName,
        firstname: result.user.displayName,
      });
      window.localStorage.setItem("token", response.token);
      window.location.assign("/");
    } catch (error) {
      setAuthError(getErrorMessage(error.code));
    }
  };

  const loginWithMicrosoft = async () => {
    try {
      const result = await signInWithPopup(auth, providerMicrosoft);
      const idToken = await result.user.getIdToken();

      const response = await request("/user/register", {
        idToken,
        email: result.user.email,
        password: "",
        lastname: result.user.displayName.split(" ")[1],
        firstname: result.user.displayName.split(" ")[0],
        profile_pic: result.user.photoURL,
      });
      window.localStorage.setItem("token", response.token);
      window.location.assign("/");
    } catch (error) {
      setAuthError(getErrorMessage(error.code));
    }
  };
  const register = async (
    { email, password, lastname, firstname },
    { setSubmitting, setErrors }
  ) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const idToken = await userCredential.user.getIdToken();
      const response = await request("/user/register", {
        idToken,
        email: email,
        password: password,
        lastname: lastname,
        firstname: firstname,
      });

      window.localStorage.setItem("token", response.token);
      window.location.href = "/";
    } catch (error) {
      setErrors({ general: getErrorMessage(error.code) });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="authContainer">
      <Container className="d-flex align-items-center justify-content-center h-100">
        <Row className="justify-content-md-center ">
          <div className="col-12 col-md-9 col-lg-12  min-h-lg-screen d-flex flex-column justify-content-center position-relative">
            <Col md="auto">
              <div className="text-center mb-4">
                <div className="gradient"></div>
                <img
                  className="mb-4"
                  src={logo}
                  alt=""
                  width="172"
                  height="157"
                />
                <h1 className="h3 mb-3 font-weight-normal">
                  Create a new account
                </h1>
                {authError ? (
                  <span className="alert alert-danger">{authError}</span>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-row justify-content-between">
                <div className="registration-form mr-4">
                  <div className="third-party-signup mb-3 d-flex justify-content-center">
                    <Button
                      variant="primary"
                      className="m-1"
                      onClick={loginWithGoogle}
                    >
                      <i className="bi bi-google"></i>
                      <span style={{ marginLeft: "10px", color: "white" }}>
                        Sign up with Google
                      </span>
                    </Button>
                    <Button
                      variant="primary"
                      className="m-1"
                      onClick={loginWithMicrosoft}
                    >
                      <i className="bi bi-microsoft"></i>
                      <span style={{ marginLeft: "10px", color: "white" }}>
                        Sign up with Microsoft
                      </span>
                    </Button>
                  </div>
                  <div className="text-center mt-4" style={{ color: "white" }}>
                    <p className="mb-0">
                      Already have an account? <Link to="/signin">Sign In</Link>
                    </p>
                  </div>
                </div>
                <div className="image-container"></div>
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default RegisterPage;
