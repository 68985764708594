export default function Header(props) {
  return (
    <header className="border-top">
      <div className="container-xl">
        <div className="py-6 border-bottom">
          {/* Page heading */}
          <div>
            <div className="row align-items-center">
              <div className="col-md-6 col-12 mb-3 mb-md-0">
                {/* Title */}
                <h3 className="h2 mb-0 ls-tight">{props.title}</h3>
              </div>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
