import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { Icon } from "../Icon";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    marginLeft: 4,
  },
});

export function TextIcon({ style, color, text, iconName, size = 10 }) {
  return (
    <View style={[styles.container, style]}>
      <Icon color={color} size={size} name={iconName} />
      <Text style={styles.text}>{text}</Text>
    </View>
  );
}
