import { AiOutlineDelete } from "react-icons/ai";
import { useTranslation } from "react-i18next";
export default function Intrests(props) {
  const { t } = useTranslation();

  return (
    <div className="card py-3 p editorcard mt-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 card-body">
            <div className="d-flex justify-content-end">
              <button
                className="mx-1 deleteItem text-danger btn btn-link"
                onClick={props.delete}
                data-item={props.dkey}
                data-type="skills"
                style={{
                  zIndex: "7000",
                }}
              >
                <AiOutlineDelete data-type="skills" data-item={props.dkey} />
              </button>
            </div>
            <div className="">
              <label className="form-label" htmlFor="last_name">
                Level:
              </label>
              <input
                type="range"
                onChange={props.handler}
                data-key={props.dkey}
                value={props?.data?.level}
                min="0"
                max="5"
                step="0.5"
                className={`form-range ${
                  props?.data?.errors?.level ? "border-danger" : ""
                }`}
                data-type="skills"
                id="level"
              />
              {props?.data?.errors?.level && (
                <div className="text-danger">{props.skills.errors.level}</div>
              )}
            </div>
            <div className="">
              <label className="form-label" htmlFor="last_name">
                Skill:
              </label>
              <input
                type="text"
                onChange={props.handler}
                data-key={props.dkey}
                value={props?.data?.title}
                className={`form-control ${
                  props?.data?.errors?.title ? "border-danger" : ""
                }`}
                data-type="skills"
                id="title"
              />
              {props?.data?.errors?.title && (
                <div className="text-danger">{props.data.errors.title}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
