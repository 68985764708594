import { useState, useEffect } from "react";
import axios from "axios";
import constants from "../constants/api";
axios.defaults.baseURL = constants.API_BASE_URL;
export function useApi(url) {
  axios.defaults.headers["authorization"] =
    "Bearer " + localStorage.getItem("Authorization");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoding] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [refreshIndex, setRefreshIndex] = useState(0);

  useEffect(() => {
    setLoding(true);
    axios
      .get(url)
      .then((r) => {
        setData(r.data);
        setLoding(false);
        setLoaded(true);
      })
      .catch((error) => {
        setLoding(false);
        if (error.response) {
          setError(error.response.data);
        } else {
          setError(error.message);
        }
      });
  }, [url, refreshIndex]);
  return [data, loading, loaded, error, refreshIndex, setRefreshIndex];
}
