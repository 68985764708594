import {
  QuestionCircleOutlined,
  EyeOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { FloatButton } from "antd";
import { useTranslation } from "react-i18next";

const EditorFloatingButtons = ({ showPreview, updateResume }) => {
  const { t } = useTranslation();
  return (
    <>
      <FloatButton.Group shape="square">
        <FloatButton
          description={t("help")}
          icon={<QuestionCircleOutlined />}
        />
        <FloatButton description={t("view")} icon={<EyeOutlined />} />
        <FloatButton description={t("save")} icon={<CheckOutlined />} />
      </FloatButton.Group>
    </>
  );
};
export default EditorFloatingButtons;
