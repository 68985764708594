import { Fragment, useContext, useState, useEffect } from "react";
import Navbar from "../../containers/navbar";
import { UserContext } from "../../contexts/User";
import Header from "../../components/header";
import { Link } from "react-router-dom";
import { useApi } from "../../hooks/useApi";
import { Empty } from "antd";
import Scanner from "../ats/index";
export default function Home(props) {
  const [invites, isLoading, loaded, error] = useApi("/invitations/fetch");

  const [state] = useContext(UserContext);
  const [data, setUser] = useState({});
  const [inv, setInv] = useState({});
  useEffect(() => {
    setUser(state);
  }, [state]);
  useEffect(() => {
    setInv(invites);
  }, [invites]);
  return (
    <Fragment>
      <Header title="🤖 Resume Scanner" />
      <Scanner />
    </Fragment>
  );
}
