import React from "react";
import {
  Card,
  List,
  Divider,
  Statistic,
  Progress,
  Table,
  Col,
  Row,
  Tag,
  Typography,
} from "antd";
import {
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const { Meta } = Card;
const { Text } = Typography;

const AtsResponse = ({ result }) => {
  const {
    contact_information,
    cv_compatibility_score,
    cover_letter_score,
    skills_match_score,
    education_match,
    keywords_to_add,
    words_to_avoid,
    word_count,
    key_words_highlights,
    comparison_of_keywords,
    tips_to_improve_cv,
  } = result;
  const comparisonColumns = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      render: (value) => <Progress percent={value} status="active" />,
    },
  ];
  const comparisonData = Object.entries(comparison_of_keywords).map(
    ([key, value]) => ({
      key,
      keyword: key,
      score: value,
    })
  );
  const keyWordsColumns = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      render: (value) => (
        <Progress
          percent={value}
          status="active"
          strokeWidth={12}
          strokeColor={{
            "0%": "#108ee9",
            "100%": "#87d068",
          }}
        />
      ),
    },
  ];

  const keyWordsData = Object.entries(key_words_highlights).map(
    ([key, value]) => ({
      key,
      keyword: key,
      progress: value * 10,
    })
  );
  return (
    <div className="container">
      <Row gutter={[16, 16]}>
        <Col span={6} md={6} xs={24}>
          <Card title="Overall Cv Performence">
            <Row justify="center" align="middle">
              <Col>
                <Progress
                  type="circle"
                  percent={cv_compatibility_score}
                  format={(percent) => `${percent}%`}
                  strokeColor={{
                    "0%": "#108ee9",
                    "100%": "#87d068",
                  }}
                />
              </Col>
            </Row>
          </Card>
          <Divider />
          <Card title=" Cover letter Performence">
            <Row justify="center" align="middle">
              <Col>
                <Progress
                  type="circle"
                  percent={cover_letter_score}
                  format={(percent) => `${percent}%`}
                  strokeColor={{
                    "0%": "#108ee9",
                    "100%": "#87d068",
                  }}
                />
              </Col>
            </Row>
          </Card>
          <Divider />
          <Card title="Contact Information">
            <Meta
              avatar={<PhoneOutlined />}
              title={contact_information.phone}
              description={
                <a href={`mailto:${contact_information.email}`}>
                  <MailOutlined /> {contact_information.email}
                </a>
              }
            />
            <Meta
              avatar={<EnvironmentOutlined />}
              title={contact_information.name}
              description={contact_information.address}
            />
          </Card>
          <Divider />

          <Card title="Word Count">
            <Statistic
              title="Total Words"
              value={word_count}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>

        <Col span={18} md={18} xs={24}>
          <Card title="Scores">
            <Statistic
              title="CV Compatibility Score"
              value={cv_compatibility_score}
              suffix="/ 100"
              valueStyle={{ color: "#3f8600" }}
              prefix={<CheckCircleOutlined />}
            />
            <Progress percent={cv_compatibility_score} status="active" />
            <Statistic
              title="Cover Letter Score"
              value={cover_letter_score}
              suffix="/ 100"
              valueStyle={{ color: "#3f8600" }}
              prefix={<CheckCircleOutlined />}
            />
            <Progress percent={cover_letter_score} status="active" />
            <Statistic
              title="Skills Match Score"
              value={skills_match_score}
              suffix="/ 100"
              valueStyle={{ color: "#3f8600" }}
              prefix={<CheckCircleOutlined />}
            />
            <Progress percent={skills_match_score} status="active" />
            <Statistic
              title="Education Match"
              value={education_match}
              suffix="/ 100"
              valueStyle={{ color: "#3f8600" }}
              prefix={<CheckCircleOutlined />}
            />
            <Progress percent={education_match} status="active" />
          </Card>

          <Divider />
          <Card title="Tips to Improve CV">
            <List
              dataSource={tips_to_improve_cv}
              renderItem={(item, index) => {
                return (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <h3>
                          <InfoCircleOutlined style={{ color: "#3f8600" }} />
                        </h3>
                      }
                      title={<Tag color="green">Tip {index + 1}</Tag>}
                      description={<Text strong>{item}</Text>}
                    />
                  </List.Item>
                );
              }}
            />
          </Card>
          <Divider />
          <Card title="Keywords to Add">
            <List
              size="small"
              dataSource={keywords_to_add}
              renderItem={(item) => (
                <List.Item>
                  <Tag color="blue">{item}</Tag>
                </List.Item>
              )}
            />
          </Card>

          <Divider />

          <Card title="Words to Avoid">
            <List
              size="small"
              dataSource={words_to_avoid}
              renderItem={(item) => (
                <List.Item>
                  <Tag color="red">{item}</Tag>
                </List.Item>
              )}
            />
          </Card>

          <Divider />

          <Card title="Key Words Highlights">
            <Table
              columns={keyWordsColumns}
              dataSource={keyWordsData}
              pagination={false}
            />
          </Card>

          <Divider />
          <Card title="Comparison of Important Keywords">
            <Table
              columns={comparisonColumns}
              dataSource={comparisonData}
              pagination={false}
            />
          </Card>

          <Divider />
        </Col>
      </Row>
    </div>
  );
};

export default AtsResponse;
