import { AiOutlineDelete } from "react-icons/ai";
export default function Intrests(props) {
  return (
    <div className="card py-3 p editorcard mt-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 card-body">
            <div className="d-flex justify-content-end">
              <button
                className="mx-1 deleteItem text-danger btn btn-link"
                onClick={props.delete}
                data-item={props.dkey}
                data-type="interests"
                style={{
                  zIndex: "7000",
                }}
              >
                <AiOutlineDelete data-type="interests" data-item={props.dkey} />
              </button>
            </div>
            <div className="">
              <label className="form-label" htmlFor="last_name">
                Interest Name:
              </label>
              <input
                type="text"
                onChange={props.handler}
                data-key={props.dkey}
                value={props?.interest?.title}
                className={`form-control ${
                  props?.interest?.errors?.title ? "border-danger" : ""
                }`}
                id="title"
                data-type="interests"
              />
              {props?.interest?.errors?.title && (
                <div className="text-danger">{props.interest.errors.title}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
