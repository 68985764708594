import { Formik, Form, Field } from "formik";
import { Fragment } from "react";
import * as Yup from "yup";

export default function FullName(props) {
  const { ref, formRef } = props.renderer;

  const validationSchema = Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
  });

  return (
    <Fragment>
      <div class="titlecls">Lets start by your name!</div>
      <Formik
        innerRef={formRef}
        validationSchema={validationSchema}
        validateOnChange={true}
        initialValues={{}}
        isInitialValid={false}
      >
        {({ values }) => (
          <Form>
            <Field name="firstName" value={values.firstName}>
              {({ field, form: { errors } }) => (
                <input
                  id="firstName"
                  autoComplete="false"
                  ref={ref.issue("firstName")}
                  className={`start-modal-input  ${
                    errors.firstName ? "border-danger" : ""
                  }`}
                  type="text"
                  placeholder="first Name"
                  {...field}
                />
              )}
            </Field>
            <Field name="lastName" value={values.lastName}>
              {({ field, form: { errors } }) => (
                <input
                  id="lastName"
                  autoComplete="false"
                  ref={ref.issue("lastName")}
                  className={`start-modal-input ${
                    errors.lastName ? "border-danger" : ""
                  }`}
                  type="text"
                  placeholder="Last name"
                  {...field}
                />
              )}
            </Field>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}
