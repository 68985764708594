import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { OAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDRIQQ6fCo1WsHs3SYDEh4Pb1xyIhsTZz0",

  authDomain: "cvgenius-4d48d.firebaseapp.com",

  projectId: "cvgenius-4d48d",

  storageBucket: "cvgenius-4d48d.appspot.com",

  messagingSenderId: "585654119919",

  appId: "1:585654119919:web:7782afd4c813718d8c9a1a",

  measurementId: "G-5CC5T32ZGK",
};
const providerApple = new OAuthProvider("apple.com");
const providerMicrosoft = new OAuthProvider("microsoft.com");
const providerGoogle = new GoogleAuthProvider();
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, providerGoogle, providerMicrosoft, providerApple };
