import { useState } from "react";
import moment from "moment";
import { Dropdown, DropdownButton, Row, Card } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import "./CustomDropdown.scss";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import Quill from "quill";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
export default function Education(props) {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= currentYear - 100; year--) {
      years.push(year);
    }
    return years.map((year) => (
      <Dropdown.Item
        key={year}
        eventKey={year}
        active={
          moment(props?.data?.start_year).format("YYYY") === year.toString() ||
          moment(props?.data?.end_year).format("YYYY") === year.toString()
        }
      >
        {year}
      </Dropdown.Item>
    ));
  };

  // Helper function to generate month options for dropdown
  const generateMonthOptions = () => {
    const months = moment.months();
    return months.map((month, index) => (
      <Dropdown.Item
        key={index}
        eventKey={index + 1}
        active={
          moment(props?.data?.start_month).format("M") ===
            (index + 1).toString() ||
          moment(props?.data?.end_month).format("M") === (index + 1).toString()
        }
      >
        {month}
      </Dropdown.Item>
    ));
  };

  return (
    <Card className="p-4 mt-4  editorcard">
      <Card.Header
        onClick={() => setToggle(!toggle)}
        className="d-flex justify-content-start"
      >
        <div className="d-flex" onClick={() => setToggle(!toggle)}>
          <h5 style={{ textTransform: "capitalize" }}>
            <button type="button" className="btn btn-white mx-2">
              {toggle ? <DownOutlined /> : <RightOutlined />}
            </button>
            {props.data.school ? props.data.school : t("t_education")}
          </h5>
        </div>
        <div className="deleteItem">
          <button
            className="mx-1   text-danger btn btn-link"
            onClick={props.delete}
            data-item={props.dkey}
            data-type="education"
            style={{
              zIndex: "7000",
            }}
          >
            <AiOutlineDelete data-item={props.dkey} data-type="education" />
          </button>
        </div>
      </Card.Header>
      {toggle && (
        <div className={``}>
          <div className="row ">
            <div className="col-md-6 mb-3 ">
              <label className="form-label" htmlFor="school">
                {t("school")}
              </label>
              <input
                type="text"
                onChange={props.handler}
                data-key={props.dkey}
                value={props?.data?.school}
                className={`form-control ${
                  props?.data?.errors?.school ? "border-danger" : ""
                }`}
                id="school"
                data-type="education"
              />
              {props?.data?.errors?.school && (
                <div className="text-danger">{props.data.errors.school}</div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label" htmlFor="last_name">
                {t("degree")}
              </label>
              <input
                type="text"
                onChange={props.handler}
                data-key={props.dkey}
                value={props?.data?.title}
                className={`form-control ${
                  props?.data?.errors?.title ? "border-danger" : ""
                }`}
                id="title"
                data-type="education"
              />
              {props?.data?.errors?.title && (
                <div className="text-danger">{props.data.errors.title}</div>
              )}
            </div>
            <div className="col-md-6 col-12 col-lg-6">
              <div className="row">
                <div className="col-md-6 col-6 col-lg-6 mb-3">
                  <Row>
                    <label className="form-label" htmlFor="start_year">
                      {t("start_year")}
                    </label>
                  </Row>
                  <Row>
                    <DropdownButton
                      title={
                        moment(props?.data?.start_year).format("YYYY") ||
                        t("start_year")
                      }
                      onSelect={(year) => {
                        props.handler({
                          target: {
                            id: "start_year",
                            value: year,
                            dataset: {
                              key: props.dkey || 0,
                              type: "education",
                            },
                          },
                        });
                      }}
                      variant="light w-100 btn-block"
                      style={{ width: "100%" }}
                    >
                      {generateYearOptions()}
                    </DropdownButton>
                  </Row>
                </div>
                <div className="col-md-6 col-6 col-lg-6 mb-3">
                  <Row>
                    <label className="form-label" htmlFor="end_month">
                      {t("start_month")}:
                    </label>
                  </Row>
                  <Row>
                    <DropdownButton
                      title={props?.data?.start_month || t("start_month")}
                      variant="light w-100"
                      onSelect={(month) =>
                        props.handler({
                          target: {
                            dataset: {
                              key: props.dkey || 0,
                              type: "education",
                            },
                            id: "start_month",
                            value: moment(month).format("MMMM"),
                          },
                        })
                      }
                    >
                      {generateMonthOptions()}
                    </DropdownButton>
                  </Row>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 col-lg-6">
              <div className="row">
                <div className="col-md-6 col-6 mb-3">
                  <Row>
                    <label className="form-label" htmlFor="end_year">
                      {t("end_year")}:
                    </label>
                  </Row>
                  <Row>
                    <DropdownButton
                      title={
                        moment(props?.data?.end_year).format("YYYY") ||
                        t("end_year")
                      }
                      variant="light w-100"
                      onSelect={(year) =>
                        props.handler({
                          target: {
                            id: "end_year",
                            dataset: {
                              key: props.dkey || 0,
                              type: "education",
                            },
                            value: year,
                          },
                        })
                      }
                      className=""
                    >
                      {generateYearOptions()}
                    </DropdownButton>
                  </Row>
                </div>
                <div className="col-md-6 col-6 mb-3">
                  <Row>
                    <label className="form-label" htmlFor="end_month">
                      {t("end_month")}:
                    </label>
                  </Row>
                  <Row>
                    <DropdownButton
                      title={props?.data?.end_month || t("end_month")}
                      variant="light w-100"
                      onSelect={(month) =>
                        props.handler({
                          target: {
                            dataset: {
                              key: props.dkey || 0,
                              type: "education",
                            },
                            id: "end_month",
                            value: moment(month).format("MMMM"),
                          },
                        })
                      }
                    >
                      {generateMonthOptions()}
                    </DropdownButton>
                  </Row>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="form-label" htmlFor="formInputExample">
                {t("description")}
              </label>
              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                  ],
                }}
                value={props?.data?.description}
                onChange={(content, delta, source, editor) => {
                  props.handler({
                    target: {
                      id: "description",
                      value: content,
                      dataset: {
                        key: props.dkey,
                        type: "education",
                      },
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      )}
    </Card>
  );
}
