import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { pdf } from "@react-pdf/renderer";
import * as pdfjsLib from "pdfjs-dist";
import PDFPreviewCanvas from "./PDFPreviewCanvas";
import Loader from "./loader";
import ButtonGroup from "./RenderButtons";
import Basic from "../../resumes/basic";
import "./style.css";
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const CreatorPreview = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [pdfDoc, setPdfDoc] = useState(null);
  const [font, setFont] = useState(props.theme.fontFamily);
  const [color, setColor] = useState(props.theme.primaryColor);
  const timeoutRef = useRef(null);
  const prevPropsRef = useRef({});
  const latestUpdate = useRef(Date.now());

  const generatePdfUrl = useMemo(() => {
    return async () => {
      const blob = await pdf(
        <Basic fontFamily={font} primaryColor={color} {...props} />
      ).toBlob();
      return URL.createObjectURL(blob);
    };
  }, [font, color, props]);

  useEffect(() => {
    props.updateForm({
      target: {
        id: "font",
        dataset: {
          type: "theme",
        },
        value: font,
      },
    });
  }, [font]);

  useEffect(() => {
    props.updateForm({
      target: {
        id: "color",
        dataset: {
          type: "theme",
        },
        value: color,
      },
    });
  }, [color]);

  const updatePdfDoc = useCallback(async () => {
    setIsLoading(true);
    const url = await generatePdfUrl();
    const doc = await pdfjsLib.getDocument(url).promise;
    setPdfDoc(doc);
    setIsLoading(false);
  }, [generatePdfUrl]);

  useEffect(() => {
    const isEqualProps = Object.keys(props).every(
      (key) => prevPropsRef.current[key] === props[key]
    );

    if (!isEqualProps) {
      prevPropsRef.current = props;
      const timeoutId = setTimeout(() => {
        if (latestUpdate.current < Date.now() - 1000) {
          latestUpdate.current = Date.now();
          updatePdfDoc();
        }
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [props, updatePdfDoc]);

  const downloadPdf = async () => {
    if (pdfDoc) {
      const blob = await pdfDoc.getData();
      const url = URL.createObjectURL(
        new Blob([blob], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = "resume.pdf";
      link.click();
    }
  };

  return (
    <div className="mt-10 bg-secondary w-100 ">
      <div
        className="container-fluid py-10"
        style={{
          overflow: "hidden",
        }}
      >
        <div className="cv-view rounded d-flex  mt-6">
          <div className="cv-view rounded d-flex mt-6">
            <PDFPreviewCanvas
              updateForm={props.updateForm}
              pdfDoc={pdfDoc}
              font={font}
              color={color}
            />
          </div>
        </div>

        <div
          className="d-flex justify-content-center mb-12"
          style={{
            position: "relative",
            left: "0",
            right: "0",
            top: ".8rem",
            zIndex: "1",
          }}
        >
          <ButtonGroup
            font={font}
            setFont={setFont}
            color={color}
            setColor={setColor}
            downloadPdf={downloadPdf}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(CreatorPreview);
