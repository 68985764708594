import React from "react";
import { resumeReducer } from "../../reducers";

const initialState = {
  resume: null,
  template: null,
  htmlContent: null,
};
export const ResumeContext = React.createContext({
  initialState,
  dispatch: () => null,
});
export const ResumeProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(resumeReducer, initialState);
  return (
    <ResumeContext.Provider value={[state, dispatch]}>
      {children}
    </ResumeContext.Provider>
  );
};
