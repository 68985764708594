import { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import constants from "../constants/api";
import { useForm } from "../hooks/useForm";
import cogoToast from "cogo-toast";
import CreatorControls from "./CreatorControls";
import CreatorUserInfo from "./CreatorUserInfo";
import { BsBook, BsBriefcase, BsCode } from "react-icons/bs";
import CollapsableSection from "./CollapsibleSection";
import NavigationBar from "./NavigationBar";
import { snapshotSchema } from "../validations";
import CreatorPreview from "./PDFRenderer";
import PersonalInfo from "./PersonalInfo";

function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

export default function CreatorControl(props) {
  const { t } = useTranslation();
  const [resumeState, setResumeState] = useState(props.resume);
  const [dataChanged, setDataChanged] = useState(false);

  const [activeSection, setActiveSection] = useState(null);
  const [showInput, setShowInput] = useState(false);

  const [errorf, loading, loaded, error, request] = useForm();
  const [submit, setSubmit] = useState(false);
  const [showDownload, setShwoDownload] = useState(false);
  const [showDownloadButton, setShwoDownloadButton] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const debounceDelay = 2000;
  const debounceRef = useRef();

  const debouncedUpdateResume = useCallback(() => {
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      setSubmit(true);
    }, debounceDelay);
  }, []);

  useEffect(() => {
    if (submit) {
      const validateAndSubmitAsync = async () => {
        const data = resumeState;
        const formData = new FormData();
        try {
          formData.append("avatar", DataURIToBlob(data.screenshot));
          const tempUploadResponse = await request(
            "/upload/screenshot",
            formData
          );
          await request("/resume/update", {
            data: {
              ...data,
              screenshot: tempUploadResponse,
            },
            rid: data.uid,
          });
        } catch (error) {
          console.error(error);
        }
        setSubmit(false);
      };
      validateAndSubmitAsync();
    }
  }, [submit]);

  useEffect(() => {
    let timer;

    if (showDownload) {
      timer = setTimeout(() => {
        setShwoDownloadButton(true);
      }, 10000);
    }

    return () => clearTimeout(timer);
  }, [showDownload]);

  useEffect(() => {
    debouncedUpdateResume();
    setDataChanged(false);
  }, [resumeState, debouncedUpdateResume]);

  function updateResume() {
    setSubmit(true);
  }

  async function updateForm({ target }) {
    setDataChanged(true);

    const { value, id } = target;
    const { key, type } = target.dataset;

    if (id === "title" && type === "resume") {
      setResumeState({ ...resumeState, title: value });
      return;
    }

    if (id === "screenshot" && type === "resume") {
      setResumeState({ ...resumeState, screenshot: value });
      return;
    }
    if (type === "theme" && id === "color") {
      setResumeState({
        ...resumeState,
        theme: { ...resumeState.theme, primaryColor: value },
      });
      return;
    }

    if (type === "theme" && id === "secondaryColor") {
      setResumeState({
        ...resumeState,
        theme: { ...resumeState.theme, secondaryColor: value },
      });
      return;
    }

    if (type === "theme" && id === "font") {
      setResumeState({
        ...resumeState,
        theme: { ...resumeState.theme, fontFamily: value },
      });
      return;
    }

    if (type === "user") {
      setResumeState({
        ...resumeState,
        user: { ...resumeState.user, [id]: value },
      });
      return;
    }
    console.log(type);
    const updatedList = resumeState[type].map((item, index) => {
      if (index === parseInt(key)) {
        return { ...item, [id]: value };
      }
      return item;
    });

    setResumeState({ ...resumeState, [type]: updatedList });
  }

  function deleteItem({ target }) {
    const { type, item } = target.dataset;
    const data = resumeState[type];
    data.splice(parseInt(item), 1);
    setResumeState({ ...resumeState, [type]: data });
  }

  function addItem({ target }) {
    const { type } = target.dataset;
    const data = resumeState[type];
    data.push({ ...constants.RESUME[type][0] });
    setResumeState({ ...resumeState, [type]: data });
  }

  const handleShowModal = () => setShwoDownload(true);
  const handleCloseModal = () => setShwoDownload(false);
  const sections = [
    {
      id: 1,
      title: t("t_personal_info"),
      icon: <BsBook />,
      items: [resumeState?.user],
      dataType: "personal_info",
    },
    {
      id: 2,
      title: "Academic History",
      icon: <BsBook />,
      items: resumeState?.education,
      dataType: "education",
    },
    {
      id: 3,
      title: "Professional History",
      icon: <BsBook />,
      items: resumeState?.experiences,
      dataType: "experiences",
    },
    {
      id: 4,
      title: "Skills",
      icon: <BsCode />,
      items: resumeState?.skills,
      dataType: "skills",
    },
    {
      id: 5,
      title: "Interests",
      icon: <BsBriefcase />,
      items: resumeState?.interests,
      dataType: "interests",
    },
    {
      id: 6,
      title: "Languages",
      icon: <BsBook />,
      items: resumeState?.languages,
      dataType: "languages",
    },
  ];
  return (
    <div className="d-xl-flex border-top overflow-hidden">
      <div className="flex-xl-none  bg-white col-xs-12 col-md-12 col-xl-6 editorside">
        <div
          style={{
            width: "100%",
          }}
        >
          <CreatorControls
            present={resumeState}
            loaded={loaded}
            loading={loading}
            error={error}
            submit={submit}
            dataChanged={dataChanged}
            updateTitle={updateForm}
            updateResume={updateResume}
            handleShowModal={handleShowModal}
            showInput={showInput}
            setShowInput={setShowInput}
            showPreview={showPreview}
          />
        </div>

        <aside className=" bg-white h-full p-2 py-4">
          <div className="my-10 p-1" style={{ minHeight: "100vh" }}>
            <div
              style={{
                paddingBottom: "3rem",
              }}
            >
              {!activeSection && (
                <CreatorUserInfo
                  present={resumeState}
                  updateUser={updateForm}
                />
              )}
              {!activeSection && <NavigationBar setIsOpen={setActiveSection} />}
              {sections.map((section) => (
                <CollapsableSection
                  key={section.id}
                  isOpen={activeSection === section.id}
                  setIsOpen={setActiveSection}
                  id={section.id}
                  title={section.title}
                  icon={section.icon}
                  items={section.items}
                  deleteItem={deleteItem}
                  updateItem={updateForm}
                  addItem={addItem}
                  dataType={section.dataType}
                />
              ))}
            </div>
          </div>
        </aside>
      </div>
      <CreatorPreview updateForm={updateForm} {...resumeState} />
    </div>
  );
}
