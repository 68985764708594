import { Fragment, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";

export default function Skills(props) {
  const { ref } = props.renderer;
  const id = () => Math.random().toString(16).slice(2);

  const [state, setState] = useState({
    skills: [{ id: id(), level: 10 }],
  });

  const { skills } = state;

  const AddSkill = (e) => {
    const { skills } = state;
    e.preventDefault();
    skills.push({ id: id(), level: 10 });
    setState({ skills });
  };

  return (
    <Fragment>
      <div class="titlecls">
        What skills recruiters should be expecting from you?
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            {skills.map((skill, index) => {
              return (
                <div
                  key={skill.id + index}
                  className="m-auto border border-primary mb-4 card-body"
                >
                  <div class="row d-flex justify-content">
                    <div class="col-md-10"></div>
                    <div class="col-md-2">
                      <button
                        className="text-danger btn btn-link"
                        onClick={(e) => {
                          let _skill = skill;
                          e.preventDefault();
                          setState({
                            skills: skills.filter((s) => s != _skill),
                          });
                        }}
                        data-type="skills"
                        style={{
                          width: "50px",
                        }}
                      >
                        <AiOutlineDelete
                          className=""
                          data-type="skills"
                          data-item={props.dkey}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <input
                      type="text"
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        let skillRef = ref.issue(`skill-${index + 1}`);
                        skill.title = skillRef.current.value = value;
                        setState({ skills: skills });
                      }}
                      value={skill?.title}
                      className={`start-modal-input ${
                        skill?.errors?.title ? "border-danger" : ""
                      }`}
                      id="title"
                      placeholder={`My Skill ${index + 1}`}
                    />
                    {skill?.errors?.title && (
                      <div className="text-danger">{skill?.errors.title}</div>
                    )}
                  </div>
                  <div className="">
                    <label className="form-label" htmlFor="level">
                      Skill Level:
                    </label>
                    <input
                      id="level"
                      type="range"
                      min="0"
                      max="100"
                      className="form-range "
                      value={skill?.level}
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        let skillRef = ref.issue(`level-${index + 1}`);
                        skill.level = skillRef.current.value = value;
                        setState({ skills: skills });
                      }}
                      step="10"
                    />
                  </div>

                  <input
                    id={`skill-${index}`}
                    type="text"
                    hidden
                    ref={ref.issue(`skill-${index + 1}`)}
                  />
                  <input
                    id={`level-${index}`}
                    type="text"
                    value={"10"}
                    hidden
                    ref={ref.issue(`level-${index + 1}`)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <a onClick={AddSkill} class="mt-6 btn btn-light btn-lg">
        Add Skill
      </a>
    </Fragment>
  );
}
