import { useState, useEffect } from "react";

import { useApi } from "../../hooks/useApi";
import { Card, Button, Empty } from "antd";

import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import moment from "moment";
import Placeholder from "../../static/palceholder-cv.png";
const { Meta } = Card;

export default function Home(props) {
  const [data, loading] = useApi("/home");
  const [homeData, setHomeData] = useState({
    user: {},
    resumes: [],
    scans: [],
    resume_count: 0,
  });
  useEffect(() => {
    setHomeData(data);
  }, [data]);
  useEffect(() => {
    homeData?.resumes.map((r) => {
      r.url = `/creator/resume?id=${r.uuid}`;
      return r;
    });
  }, [homeData]);
  return (
    <div className="home p-2">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 p-2 col-12 d-flex align-items-center">
            <div className="col-auto">
              <div className="avatar avatar-md bg-warning rounded-circle text-white">
                <img alt="..." src={homeData?.user?.profile_pic} />
              </div>
            </div>
            <div className="ms-5">
              <h1 className="h2 ls-tight mb-2 text-capitalize">
                Good day, {homeData?.user?.firstname}
              </h1>
              <div className="d-flex align-items-center">
                <Link to="/profile">
                  <span className="text-muted text-sm font-semibold me-7">
                    <i className="bi bi-pencil-square me-2" />
                    Edit profile
                  </span>
                </Link>
                <span className="text-success text-sm font-semibold">
                  <i className="bi bi-patch-check-fill me-2" />
                  Beta
                </span>
              </div>
            </div>
          </div>
          {/* Actions */}
          <div className="col-lg-6 col-12 text-lg-end">
            <div className="mx-n1">
              <Link
                to="/scanner"
                className="btn d-inline-flex btn-sm btn-neutral mx-1"
              >
                <a className=" pe-2">
                  <i className="bi bi-fire" />
                </a>
                <span>New CV Scan</span>
              </Link>
              <Link
                to="/resumes"
                className="btn d-inline-flex btn-sm btn-primary mx-1"
              >
                <span className=" pe-2">
                  <i className="bi bi-plus" />
                </span>
                <span>New post</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="d-xl-flex border-top">
        <div
          className="flex-xl-none col-xl-4 h-xl-calc"
          style={{ xHXl: "4.5rem" }}
        >
          <aside className="h-full overflow-y-xl-auto p-6 border-end-xl">
            <div
              className="border border-2 bg-surface-secondary h-full border-dashed rounded d-flex flex-column p-6 "
              style={{ minHeight: "400px" }}
            >
              <div className="font-semibold ">
                {homeData?.scans &&
                  homeData?.scans.map((scan) => {
                    return (
                      <div className="card mb-2">
                        <div className="card-body">
                          {/* Title */}
                          <span className="d-block h6 text-sm font-semibold mb-2">
                            Scan Score
                          </span>
                          {/* Details */}
                          <div className="row align-items-center">
                            <div className="col">
                              <div className="row align-items-center no-gutters">
                                <div className="col-auto">
                                  <span className="h5 me-2">
                                    {scan.cv_compatibility_score}
                                  </span>
                                </div>
                                <div className="col">
                                  <div className="progress progress-sm">
                                    <div
                                      className="progress-bar bg-warning"
                                      role="progressbar"
                                      style={{
                                        width:
                                          scan.cv_compatibility_score + "%",
                                      }}
                                      aria-valuenow={
                                        scan.cv_compatibility_score
                                      }
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="icon text-warning">
                                <i className="bi bi-kanban" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {homeData?.scans?.length < 1 ? (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 60,
                    }}
                    description={<span>You Haven't Made Any Scan Yet</span>}
                  >
                    <Link to="/scanner">
                      <Button type="primary">Scan Now</Button>
                    </Link>
                  </Empty>
                ) : (
                  ""
                )}
              </div>
            </div>
          </aside>
        </div>
        <div className="min-w-0 flex-xl-fill">
          <div className="container-fluid py-6 h-full">
            <div
              className="border border-2 bg-surface-secondary h-full border-dashed rounded d-flex flex-column p-2"
              style={{ minHeight: "400px" }}
            >
              <div className="display-4 font-semibold">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card bg-primary border-primary">
                        {/* Card Body */}
                        <div className="card-body">
                          <div className="d-flex">
                            <div>
                              <div className="icon text-white">
                                <i className="bi bi-bell" />
                              </div>
                            </div>
                            <div className="ps-4">
                              <span className="d-block h4 text-white mb-1">
                                0
                              </span>
                              <span className="d-block text-sm text-white opacity-8">
                                Notifications
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card bg-danger border-danger">
                        {/* Actions */}

                        <div className="card-body">
                          <div className="d-flex">
                            <div>
                              <div className="icon text-white">
                                <i className="bi bi-file-break" />
                              </div>
                            </div>
                            <div className="ps-4">
                              <span className="d-block h4 text-white mb-1">
                                {homeData?.scans?.length}
                              </span>
                              <span className="d-block text-sm text-white opacity-8">
                                Resumes Scanned
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card bg-default border-danger">
                        {/* Actions */}

                        <div className="card-body">
                          <div className="d-flex">
                            <div>
                              <div className="icon text-primary">
                                <i className="bi bi-file-earmark-check" />
                              </div>
                            </div>
                            <div className="ps-4">
                              <span className="d-block h4 text-black mb-1">
                                {homeData?.resume_count}
                              </span>
                              <span className="d-block text-sm text-dark opacity-8">
                                Resumes Created
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-8">
                    {(() => {
                      if (homeData?.resumes?.length === 0) {
                        return;
                      }
                      return homeData?.resumes?.map((r, i) => {
                        return (
                          <div className="col-md-3" key={r.id}>
                            <Link to={r.url ? r.url : ""}>
                              <div className="card">
                                <div className="p-2">
                                  <img
                                    alt="..."
                                    src={
                                      r.screenshot ? r.screenshot : Placeholder
                                    }
                                    className="card-img"
                                  />
                                </div>
                                <div className="card-body pb-4">
                                  <div className="d-flex">
                                    <div className="flex-fill">
                                      <h5 className="mb-1">{r.title}</h5>
                                      <div className="d-flex mt-3 mx-n4">
                                        <div className="text-xs font-semibold text-muted mx-4 my-2">
                                          <i className="bi bi-calendar me-1" />
                                          {moment(r.created_at).format(
                                            "ddd .Do"
                                          )}
                                        </div>
                                        <div className="text-xs font-semibold text-muted mx-4 my-2">
                                          <i className="bi bi-clock me-1" />
                                          {moment(r.created_at).format("hh:mm")}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="ms-auto"></div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      });
                    })()}
                  </div>
                  <div className="row p-4">
                    <div className="card mt-8">
                      <div className="card-body pb-0">
                        <div className="mb-3 d-flex align-items-center">
                          <h5 className="mb-0">
                            Visit Our Help Desk If You Need Assistance
                          </h5>
                          <div className="ms-auto text-end">
                            <a href="#" className="text-sm font-semibold">
                              Help Desk
                            </a>
                          </div>
                        </div>
                        <div className="list-group list-group-flush">
                          <div className="list-group-item d-flex align-items-center px-0">
                            <div className="flex-fill">
                              {/* Title */}
                              <a
                                href="https://support.cvgenius.ai/portal/en/kb/cvgenius"
                                className="d-block h6 font-semibold mb-1"
                              >
                                New To CvGenius?{" "}
                              </a>
                              {/* Subtitle */}
                              {/* Badges */}
                              <span className="d-block text-sm mb-2">
                                A Collection of Resource to help you get strated
                              </span>
                              <div className="d-flex mx-n1"></div>
                            </div>
                            <div className="ms-auto text-end">
                              <a
                                href="https://support.cvgenius.ai/portal/en/kb/cvgenius"
                                className="btn btn-sm btn-neutral"
                              >
                                More
                              </a>
                            </div>
                          </div>
                          <div className="list-group-item d-flex align-items-center px-0">
                            <div className="flex-fill">
                              {/* Title */}
                              <a
                                href="https://support.cvgenius.ai/portal/en/newticket?departmentId=902382000000006907"
                                className="d-block h6 font-semibold mb-1"
                              >
                                Submit a ticket
                              </a>
                              {/* Subtitle */}
                              <span className="d-block text-sm mb-2">
                                Facing a Problem? Open a ticket
                              </span>
                              {/* Badges */}
                              <div className="d-flex mx-n1"></div>
                            </div>
                            <div className="ms-auto text-end">
                              <a
                                href="https://support.cvgenius.ai/portal/en/newticket?departmentId=902382000000006907"
                                className="btn btn-sm btn-neutral"
                              >
                                More
                              </a>
                            </div>
                          </div>
                          <div className="list-group-item d-flex align-items-center px-0">
                            <div className="flex-fill">
                              {/* Title */}
                              <a
                                href="https://support.cvgenius.ai/portal/en/kb/articles/how-to-use-our-resume-scanner-to-improve-your-cv"
                                className="d-block h6 font-semibold mb-1"
                              >
                                Scanning a Resume
                              </a>
                              {/* Subtitle */}
                              <span className="d-block text-sm mb-2">
                                Need Help With Scanning A Resume?
                              </span>
                              {/* Badges */}
                              <div className="d-flex mx-n1"></div>
                            </div>
                            <div className="ms-auto text-end">
                              <a
                                href="https://support.cvgenius.ai/portal/en/kb/articles/how-to-use-our-resume-scanner-to-improve-your-cv"
                                className="btn btn-sm btn-neutral"
                              >
                                More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
