import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Navigate } from "react-router-dom";
import constants from "../constants/api";
axios.defaults.baseURL = constants.API_BASE_URL;
export function useForm() {
  axios.defaults.headers["authorization"] =
    "Bearer " + localStorage.getItem("Authorization");

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoding] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const request = async (uri, payload) => {
    setLoding(true);
    console.log("pay", payload);
    const resp = await axios.post(uri, payload).then((resp) => {
      setData(resp.data);
      setLoding(false);
      setLoaded(true);
      return resp.data;
    });
    return resp;
  };
  return [data, loading, loaded, error, request];
}
