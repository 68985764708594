import { Fragment, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";

export default function Hobbies(props) {
  const { ref } = props.renderer;

  const id = () => Math.random().toString(16).slice(2);

  const [state, setHobbies] = useState({ hobbies: [{ id: 0 }] });

  const { hobbies } = state;

  const AddSkill = (e) => {
    e.preventDefault();
    hobbies.push({ id: id() });
    setHobbies({ hobbies });
  };

  return (
    <Fragment>
      <div class="titlecls">What do you do in your free time?</div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            {hobbies.map((hobby, index) => {
              return (
                <div
                  key={hobby.id + index}
                  className="m-auto border border-primary mb-4 card-body"
                >
                  <div class="row d-flex justify-content">
                    <div class="col-md-10"></div>
                    <div class="col-md-2">
                      <button
                        className="text-danger btn btn-link"
                        onClick={(e) => {
                          let _hobby = hobby;
                          e.preventDefault();
                          setHobbies({
                            hobbies: hobbies.filter((s) => s != _hobby),
                          });
                        }}
                        data-type="skills"
                        style={{
                          width: "50px",
                        }}
                      >
                        <AiOutlineDelete className="" data-type="skills" />
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <input
                      type="text"
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        let hobbyRef = ref.issue(`hobby-${index + 1}`);
                        hobby.title = hobbyRef.current.value = value;
                        setHobbies({ hobbies });
                      }}
                      value={hobby?.title}
                      className={`start-modal-input ${
                        hobby?.errors?.title ? "border-danger" : ""
                      }`}
                      id="title"
                      placeholder={`My Hobby ${index + 1}`}
                    />
                    {hobby?.errors?.title && (
                      <div className="text-danger">{hobby?.errors.title}</div>
                    )}
                  </div>

                  <input
                    id={`hobby-${index}`}
                    type="text"
                    hidden
                    ref={ref.issue(`hobby-${index + 1}`)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <a onClick={AddSkill} class="mt-6 btn btn-light btn-lg">
        Add Hobby
      </a>
    </Fragment>
  );
}
