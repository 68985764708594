import { Card, Button } from "react-bootstrap";

export default function TemplateList(props) {
  return (
    <Button
      className="col-md-3 btn-role"
      role="button"
      onClick={props.handleCheck}
    >
      <Card border={!props.selected ? "dark" : "success"} className="">
        <Card.Img
          variant="top"
          role="button"
          value={props.uuid}
          src={props.image}
          alt="..."
        />
        <Card.Footer>
          <Card.Title>{props.name}</Card.Title>
        </Card.Footer>
      </Card>
    </Button>
  );
}
