import { login } from "../data/userData";
import axios from "axios";

function setTemplate(state, payload) {
  return {
    ...state,
    ...payload,
  };
}

function createResume(state, payload) {
  return {
    ...state,
    ...payload,
  };
}

export default function resumeReducer(state, action) {
  switch (action.type) {
    case "SET_TEMPLATE":
      return setTemplate(state, { template: action.payload });
    case "SET_HTML":
      return setTemplate(state, { htmlContent: action.payload });
    case "CREATE_RESUME":
      return createResume(state, { resume: action.payload });
    case "UPDATE_RESUME":
      return createResume(state, { resume: action.payload });
    default:
      throw new Error("no action found!");
  }
}
