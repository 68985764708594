import { Formik, Form, Field, ErrorMessage } from "formik";
import moment from "moment";
import { Fragment, useState } from "react";
import * as Yup from "yup";

const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear; year >= currentYear - 100; year--) {
    years.push(year);
  }
  return years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));
};

// Helper function to generate month options for select input
const generateMonthOptions = () => {
  const months = moment.months();
  return months.map((month, index) => (
    <option key={index} value={index + 1}>
      {month}
    </option>
  ));
};

export default function CurrentExperience(props) {
  const { ref, formRef } = props.renderer;

  const validationSchema = Yup.object({
    title: Yup.string().required(),
    institution: Yup.string().required(),
    description: Yup.string().required(),
    end_year: Yup.number().when("isCurrent", {
      is: false,
      then: (schema) => schema.required(),
    }),
    start_year: Yup.number().required().min(4),
    start_month: Yup.number().required().min(1),
    end_month: Yup.number().when("isCurrent", {
      is: false,
      then: (schema) => schema.required(),
    }),
    isCurrent: Yup.boolean(),
  });

  const [isCurrent, setIsCurrent] = useState(true);

  return (
    <Fragment>
      <div class="titlecls">Any current profession Role?</div>
      <Formik
        isInitialValid={false}
        innerRef={formRef}
        validationSchema={validationSchema}
        validateOnChange={true}
        initialValues={{ isCurrent: true }}
      >
        {({ values }) => (
          <Form>
            <Field name="title" value={values.title}>
              {({ field, form: { errors } }) => (
                <div>
                  <input
                    id="title"
                    autoComplete="false"
                    ref={ref.issue("title")}
                    className={`start-modal-input ${
                      errors.title ? "border-danger" : ""
                    }`}
                    type="text"
                    placeholder="Software engineer"
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="institution" value={values.institution}>
              {({ field, form: { errors } }) => (
                <div>
                  <input
                    id="institution"
                    autoComplete="false"
                    ref={ref.issue("institution")}
                    className={`start-modal-input ${
                      errors.institution ? "border-danger" : ""
                    }`}
                    type="text"
                    placeholder="At Google"
                    {...field}
                  />
                </div>
              )}
            </Field>
            <div class="row">
              <div className="col-md-6">
                <Field name="start_year" value={values.start_year} as="select">
                  {({ field, form: { errors } }) => (
                    <select
                      className={
                        "start-modal-input text-md " +
                        `${errors.start_year ? "border-danger" : ""}`
                      }
                      id="start_year"
                      ref={ref.issue("start_year")}
                      {...field}
                    >
                      <option value="">START YEAR</option>
                      {generateYearOptions()}
                    </select>
                  )}
                </Field>
              </div>
              <div className="col-md-6">
                <Field
                  name="start_month"
                  value={values.start_month}
                  as="select"
                >
                  {({ field, form: { errors } }) => (
                    <select
                      className={
                        "start-modal-input text-md " +
                        `${errors.start_month ? "border-danger" : ""}`
                      }
                      id="start_month"
                      ref={ref.issue("start_month")}
                      {...field}
                    >
                      <option value="">START MONTH</option>
                      {generateMonthOptions()}
                    </select>
                  )}
                </Field>
              </div>
            </div>
            <div class="row mt-4">
              <div className="col-md-3">
                <Field
                  name="isCurrent"
                  toggle={values.isCurrent}
                  type="checkbox"
                  validate={(e) => {
                    setIsCurrent(e);
                  }}
                >
                  {({ field, form: { errors } }) => (
                    <input
                      className={"form-check-input"}
                      id="isCurrent"
                      type="checkbox"
                      ref={ref.issue("isCurrent")}
                      {...field}
                    ></input>
                  )}
                </Field>
                <label class="form-check-label text-md" for="isCurrent">
                  &nbsp;&nbsp; Is Current ?
                </label>
              </div>
            </div>
            {isCurrent ? (
              ""
            ) : (
              <div class="row mt-3">
                <div className="col-md-6">
                  <Field name="end_year" value={values.end_year} as="select">
                    {({ field, form: { errors } }) => (
                      <select
                        className={
                          "start-modal-input text-md " +
                          `${errors.end_year ? "border-danger" : ""}`
                        }
                        id="end_year"
                        ref={ref.issue("end_year")}
                        {...field}
                      >
                        <option value="">END YEAR</option>
                        {generateYearOptions()}
                      </select>
                    )}
                  </Field>
                </div>
                <div className="col-md-6">
                  <Field name="end_month" value={values.end_month} as="select">
                    {({ field, form: { errors } }) => (
                      <select
                        className={
                          "start-modal-input text-md " +
                          `${errors.end_month ? "border-danger" : ""}`
                        }
                        id="end_month"
                        ref={ref.issue("end_month")}
                        {...field}
                      >
                        <option value="">END MONTH</option>
                        {generateMonthOptions()}
                      </select>
                    )}
                  </Field>
                </div>
              </div>
            )}
            <div class="row mt-4">
              <div className="col-md-12">
                <Field
                  name="description"
                  value={values.description}
                  as="textarea"
                >
                  {({ field, form: { errors } }) => (
                    <textarea
                      className={
                        "start-modal-input text-primary text-md " +
                        `${errors.description ? "border-danger" : ""}`
                      }
                      id="description"
                      ref={ref.issue("description")}
                      {...field}
                      placeholder="Describe it a little bit"
                    ></textarea>
                  )}
                </Field>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}
