import { Fragment, useContext, useState, useEffect } from "react";
import Navbar from "../../containers/navbar";
import { UserContext } from "../../contexts/User";
import { useForm } from "../../hooks/useForm";
import { InfoBox } from "../../components/InfoBox";
import { Form } from "react-bootstrap";

export default function Profile(props) {
  const [state, dispatch] = useContext(UserContext);
  const [user, setUser] = useState({});
  const [submit, setSubmit] = useState(false);
  const [data, loading, loaded, error, request] = useForm();
  useEffect(() => {
    if (state.user) {
      setUser(state.user);
    }
  }, [state]);

  useEffect(() => {
    (async function () {
      if (submit) {
        await request("/profile/update", user);
        setSubmit(false);
      }
    })();
  }, [submit, user, request]);

  useEffect(
    function () {
      dispatch({ type: "LOGIN_ACTION", payload: data }, state);
    },
    [data, dispatch]
  );

  function inputChange(e) {
    const { id, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }
  function checkBoxInput({ target }) {
    let { id } = target;

    setUser({
      ...user,
      [id]: !Boolean(user[id]),
    });
  }

  function updateProfile() {
    setSubmit(true);
  }
  return (
    <Fragment>
      <Fragment>
        <header className="border-top">
          <div className="container-xl">
            <div className="py-6 border-bottom">
              <div>
                <div className="row align-items-center">
                  <div className="col-md-6 col-12 mb-3 mb-md-0">
                    <h1 className="h2 mb-0 ls-tight">Profile</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-7 mx-auto">
              <div className="card shadow border-0 mt-4 mb-10">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-lg bg-warning rounded-circle text-white">
                          <img
                            alt="..."
                            src={user?.profile_pic ? user.profile_pic : ""}
                          />
                        </div>
                        <div className="ms-4">
                          <span className="h4 d-block mb-0">
                            {user?.firstname + " " + user?.lastname}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="ms-auto">
                      <button type="button" className="btn btn-sm btn-neutral">
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <h5 className="mb-0">Contact Information</h5>
              </div>
              <form className="mb-6">
                {error ? <InfoBox type="danger" text={error.message} /> : ""}

                <div className="row mb-5">
                  <div className="col-md-6">
                    <div>
                      <label className="form-label" htmlFor="firstname">
                        First name
                      </label>
                      <input
                        type="text"
                        onChange={inputChange}
                        className="form-control"
                        id="firstname"
                        value={user?.firstname ? user.firstname : ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label className="form-label" htmlFor="lastname">
                        Last name
                      </label>
                      <input
                        type="text"
                        onChange={inputChange}
                        className="form-control"
                        id="lastname"
                        value={user?.lastname ? user.lastname : ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="row g-5">
                  <div className="col-md-6">
                    <div>
                      <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        onChange={inputChange}
                        className="form-control"
                        id="email"
                        value={user?.email ? user.email : ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label className="form-label" htmlFor="phone">
                        Phone number
                      </label>
                      <input
                        type="tel"
                        onChange={inputChange}
                        className="form-control"
                        id="phone"
                        value={user?.phone ? user.phone : ""}
                      />
                    </div>
                  </div>
                  <div className="col-8">
                    <div>
                      <label className="form-label" htmlFor="address">
                        Address
                      </label>
                      <input
                        type="text"
                        onChange={inputChange}
                        className="form-control"
                        id="address"
                        value={user?.address ? user.address : ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <label className="form-label" htmlFor="zip">
                        City
                      </label>
                      <input
                        type="tel"
                        onChange={inputChange}
                        className="form-control"
                        value={user?.city ? user.city : ""}
                        id="city"
                      />
                    </div>
                  </div>{" "}
                  <div className="col-md-2">
                    <div>
                      <label className="form-label" htmlFor="zip">
                        ZIP
                      </label>
                      <input
                        type="tel"
                        onChange={inputChange}
                        className="form-control"
                        value={user?.zip_code ? user.zip_code : ""}
                        id="zip_code"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="check_primary_address"
                        id="check_primary_address"
                      />

                      <label
                        className="form-check-label"
                        htmlFor="check_primary_address"
                      >
                        Make this my default address
                      </label>
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <button
                    onClick={updateProfile}
                    className="btn btn-md btn-primary"
                  >
                    Update
                  </button>
                </div>
              </form>
              <hr className="my-10" />
              <div className="row g-6">
                <div className="col-md-6">
                  <div className="card shadow border-0">
                    <div className="card-body">
                      <h5 className="mb-2">Public profile</h5>
                      <p className="text-sm text-muted mb-6">
                        Making your profile public means that anyone on the
                        network will be able to find you.
                      </p>
                      <Form.Check
                        as="input"
                        type="switch"
                        id="image_consent"
                        onChange={checkBoxInput}
                        checked={user?.image_consent}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card shadow border-0">
                    <div className="card-body">
                      <h5 className="mb-2">Show my email</h5>
                      <p className="text-sm text-muted mb-6">
                        Showing your e-mail adresses means that anyone on the
                        network will be able to find you.
                      </p>
                      <Form.Check
                        as="input"
                        type="switch"
                        id="visibility_consent"
                        onChange={checkBoxInput}
                        checked={user?.visibility_consent}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="card shadow border-0">
                    <div className="card-body d-flex align-items-center">
                      <div>
                        <h5 className="text-danger mb-2">Deactivate account</h5>
                        <p className="text-sm text-muted">
                          Once you delete your account, there is no going back.
                          Please be certain.
                        </p>
                      </div>
                      <div className="ms-auto">
                        <button type="button" className="btn btn-sm btn-danger">
                          Deactivate
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          r
        </div>
      </Fragment>
    </Fragment>
  );
}
