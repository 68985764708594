import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoArrowBackOutline } from "react-icons/io5";
import { SyncOutlined, CheckOutlined, EditOutlined } from "@ant-design/icons";

import { Row, Col, Input, Button, Menu, Dropdown, Space } from "antd";
import {
  DownloadOutlined,
  SaveOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import EditorFloatingButtons from "./EditorFloatingButtons";
import LanguageSelector from "./LanguageSelector";

const CreatorControls = ({
  present,
  updateTitle,
  setShowPreview,
  showPreview,
  loaded,
  loading,
  error,
  submit,
  dataChanged,
  updateResume,
  handleShowModal,
  showInput,
}) => {
  const { t, i18n } = useTranslation();

  const handleTitleChange = (e) => {
    updateTitle({
      target: {
        id: "title",
        value: e.target.value,
        dataset: {
          type: "resume",
        },
      },
    });
  };

  const handleSaveClick = () => {
    updateResume();
  };

  const mobileMenu = (
    <Menu>
      <Menu.Item key="1">
        <Button onClick={handleSaveClick}>{t("save")}</Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button onClick={handleShowModal}>{t("download")}</Button>
      </Menu.Item>
      <Menu.Item key="3">
        <LanguageSelector />
      </Menu.Item>
    </Menu>
  );
  let inputIcon;

  const iconStyle = {
    fontSize: "1rem",
    color: "#bae0ff",
  };

  const iconGreen = {
    fontSize: "1rem",
    color: "#52c41a",
  };

  if (loading) {
    inputIcon = <SyncOutlined spin style={iconStyle} />;
  } else if (loaded) {
    inputIcon = <CheckOutlined style={iconGreen} />;
  } else if (dataChanged) {
    inputIcon = <EditOutlined style={iconStyle} />;
  }

  return (
    <>
      <Container fluid className="creatorControl">
        <Row className="creator-control w-100 bg-primary position-sticky">
          <Col xs={8} md={10} className="py-2">
            <Link to={"/resumes"}>
              <Button type="primary" shape="squared">
                <IoArrowBackOutline />
              </Button>
            </Link>
          </Col>
          <Col xs={12} md={7} className="py-2">
            <Row>
              <Col>
                <Input
                  bordered={false}
                  value={present.title}
                  className="input creator-input"
                  style={{
                    color: "white",
                    fontSize: "1rem",
                  }}
                  onChange={handleTitleChange}
                />
              </Col>
              {inputIcon}
            </Row>
          </Col>
          <Col xs={4} md={6} className="py-2">
            <Row justify="space-arround" align="end">
              <div className="d-none d-md-block mx-2">
                <LanguageSelector />
              </div>
              <Button
                type="default"
                className="d-none d-md-block"
                icon={<DownloadOutlined />}
                onClick={handleShowModal}
              >
                {t("download")}
              </Button>
              <Dropdown
                overlay={mobileMenu}
                className="d-block d-md-none"
                placement="bottomRight"
              >
                <Button type="default" shape="default">
                  <i className="bi bi-three-dots-vertical mx-2"></i>
                </Button>
              </Dropdown>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <EditorFloatingButtons />
      </Container>
    </>
  );
};
export default CreatorControls;
