import "./steps.css";
import "./stepsHeader.css";

import React, { Fragment, useState, useEffect } from "react";
import { steps, Render } from "./steps";
import { useForm } from "../../hooks/useForm";

function makeReferences() {
  let isValidForRef = (s) => typeof steps[s] == "object" && steps[s].steps;
  let stepsKeys = Object.keys(steps).filter(isValidForRef);
  return stepsKeys.map((stepKey) => [
    Object.freeze({
      title: steps[stepKey]["title"],
      icon: steps[stepKey]["icon"],
      canSkip: steps[stepKey]["skipAllowed"],
      hideContinue: steps[stepKey]["hideContinue"],
      stepKey,
      stepRef: React.createRef(),
    }),
    steps[stepKey].steps.map((_, index) => {
      let entity = {
        transformer:
          steps[stepKey]["transformers"] &&
          steps[stepKey]["transformers"][index]
            ? steps[stepKey]["transformers"][index]
            : undefined,
        stepKey,
        name: _.prototype.constructor.name,
        canSkip: steps[stepKey]["skipAllowed"],
        hideContinue: steps[stepKey]["hideContinue"],
        ref: {
          issue(key) {
            if (!this._refs) {
              // object a zebi
              let rfs = {};
              Object.defineProperty(this, "_refs", {
                get: function () {
                  return rfs;
                },
              });
            }
            let issued = this._refs[key];
            if (!issued) issued = this._refs[key] = React.createRef();
            return issued;
          },
        },
        stepRef: React.createRef(),
        formRef: React.createRef(),
        toData() {
          if (!this.ref || !this.ref._refs) return;
          let dataKeys = Object.keys(this.ref._refs);
          return dataKeys.reduce((a, b) => {
            if (!a[b]) {
              let targetRef = this.ref._refs[b];
              let value = undefined;
              if (targetRef.current) {
                value = targetRef.current.value;
              }
              a[b] = value;
            }
            return a;
          }, {});
        },
      };

      return Object.freeze(entity);
    }),
  ]);
}

const refs = makeReferences();

class SingleCallbackEventEmitter {
  constructor() {
    this.callbacks = {};
  }

  on(event, cb) {
    if (!this.callbacks[event]) {
      this.callbacks[event] = cb;
    }
  }

  emit(event, data) {
    let cb = this.callbacks[event];
    if (cb) {
      cb(data);
    }
  }
}

const eventEmitter = new SingleCallbackEventEmitter();

export default function Start(props) {
  const [resumeData, , , , request] = useForm();

  const [state, setState] = useState({
    currentRef: refs[0][1],
    stepRef: refs[0][1][0]["stepRef"],
    subStepIndex: 0,
    stepIndex: 0,
    oldIndex: 0,
    completed: false,
    data: {},
  });

  const { completed, stepRef, stepIndex, subStepIndex, currentRef } = state;

  const getNextState = () => {
    const nextStepRef = currentRef[subStepIndex + 1];
    if (nextStepRef) {
      return {
        ...state,
        previous: state,
        subStepIndex: subStepIndex + 1,
        stepRef: refs[stepIndex][1][subStepIndex + 1]["stepRef"],
      };
    } else {
      let newIndex = stepIndex + 1;
      if (refs[newIndex]) {
        return {
          ...state,
          previous: state,
          currentRef: refs[newIndex][1],
          stepRef: refs[newIndex][1][0]["stepRef"],
          stepIndex: newIndex,
          oldIndex: stepIndex,
          subStepIndex: 0,
        };
      } else {
        // completed;
        return { ...state, completed: true };
      }
    }
  };

  const complete = async () => {
    if (!resumeData || !resumeData.id) {
      const { data } = state;
      const { biography, template: temp, education, experience } = data;
      const { Template } = temp;

      console.log(education);
      console.log(experience);

      await request("/resume/update", {
        data: {
          template_id: Template.templateId,
          title: `${biography.FullName.firstName} ${biography.FullName.lastName}`,
        },
      });
    }
    window.location.href = "/creator/resume?id=" + resumeData.id;
  };

  const skip = (e) => next(e, false);

  const next = (e, validation = true) => {
    e.preventDefault();

    if (completed) return complete();

    // this scope is already dispatched and been rendered
    // there for we don't want it to reference the onInitialization-state primitive types declared above
    const { data, stepRef, subStepIndex, currentRef } = state;

    const {
      name: stepName,
      formRef,
      stepKey,
      transformer,
    } = currentRef[subStepIndex] || {};

    let form = formRef.current;

    stepRef.current?.classList?.add("start-animate-out");

    // next exists, add listener
    if (form && !form.isValid && validation) {
      stepRef.current?.classList?.remove("start-animate-out");
      form.validateForm();
      return;
    }

    let subStepData = currentRef[subStepIndex].toData();
    subStepData = transformer?.apply
      ? transformer?.apply(null, [subStepData])
      : subStepData;

    if (!data[stepKey]) data[stepKey] = {};

    Object.defineProperty(data[stepKey], stepName, {
      value: subStepData,
      configurable: true,
    });

    const nextState = getNextState();

    const spanRef = document.getElementById("progressbar");
    const nextSpan = spanRef.childNodes.item(nextState.stepIndex);

    let nextSubStep;
    if ((nextSubStep = nextState.currentRef[nextState.subStepIndex])) {
      const { name } = nextSubStep;

      eventEmitter.emit(name.toLowerCase(), data);
    }

    setTimeout(() => {
      stepRef.current?.classList?.remove("start-is-showing");

      if (nextState.oldIndex != nextState.stepIndex) {
        if (nextSpan) nextSpan.classList.add("active");
      }

      // next child step exist
      if (nextState.stepRef?.current) {
        nextState.stepRef.current.classList.add("start-is-showing");
      }

      setState(nextState);
    }, 600);
  };

  const previous = (e) => {
    e.preventDefault();
    const { previous, stepRef, stepIndex } = state;

    stepRef.current.classList.add("start-animate-out");

    const spanRef = document.getElementById("progressbar");
    const nextSpan = spanRef.childNodes.item(stepIndex);

    setTimeout(() => {
      stepRef.current.classList.remove("start-is-showing");
      stepRef.current.classList.remove("start-animate-out");

      if (previous.oldIndex != state.stepIndex) {
        if (nextSpan) nextSpan.classList.add("active");
      }

      if (previous.stepRef) {
        previous.stepRef.current.classList.add("start-is-showing");
        previous.stepRef.current.classList.remove("start-animate-out");
      }

      setState(previous);
    }, 600);
  };

  // [Bug]
  const onPressKey = async (e) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      await next(e);
    }
  };

  // apply onEnter on all refs
  // except last ones
  const applyKeyUpEventToRefs = () => {
    refs.forEach(([_, steps]) => {
      steps
        .filter((step) => step.ref._refs)
        .forEach((step) => {
          Object.keys(step.ref._refs).forEach((_) => {
            let element = step.ref._refs[_];
            if (element && element.current) {
              element.current.removeEventListener("keyup", onPressKey);
              element.current.addEventListener("keyup", onPressKey);
            }
          });
        });
    });
  };

  useEffect(() => {
    applyKeyUpEventToRefs();

    stepRef.current?.classList.add("start-is-showing");

    const spanRef = document.getElementById("progressbar");

    const { stepIndex } = state;

    const currentSpan = spanRef?.childNodes?.item(stepIndex);
    if (currentSpan) {
      currentSpan.classList.add("active");
    }
    if (completed) {
      complete();
    }
  });

  let nextState = getNextState();

  let hideContinue = currentRef[subStepIndex]["hideContinue"],
    canReturn = !!currentRef[subStepIndex - 1] || !!state.previous,
    canSkip =
      !nextState.completed &&
      currentRef[subStepIndex] &&
      currentRef[subStepIndex]["canSkip"];

  return (
    <Fragment>
      <div class="container-fluid" id="grad1">
        <div class="row justify-content-center mt-0">
          <div class="col-12 col-lg-8 text-center">
            <div class="mt-3 mb-5">
              <div class="row">
                <div class="col-md-12 mx-0">
                  <form id="msform">
                    <ul id="progressbar">
                      {refs.map(([{ title, icon }]) => {
                        return (
                          <li key={title}>
                            <div class="mb-4">
                              <i class={`${icon} fa-4x`} />
                            </div>
                            <span class="lb">
                              <strong>{title}</strong>
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="start-modal-wrap">
        <div class="start-modal-bodies">
          <Render eventEmitter={eventEmitter} refs={refs} data={state.data} />
        </div>
      </div>
      <div class="footx border border-primary">
        <div class="row justify-content-md-center">
          {!canReturn ? (
            ""
          ) : (
            <div class="col-md-auto">
              <a
                href="#"
                onClick={previous}
                class="btn btn-secondary btn-md float-left w-auto"
              >
                Previous
              </a>
            </div>
          )}
          {hideContinue ? (
            ""
          ) : (
            <div class="col-md-auto">
              <a onClick={next} class="btx">
                Next
              </a>
            </div>
          )}
          {!canSkip ? (
            ""
          ) : (
            <div class="col-md-auto">
              <a
                onClick={skip}
                href="#"
                class="text-primary link-secondary btx"
              >
                <span class="h4">
                  <strong>Later</strong>
                </span>
              </a>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
