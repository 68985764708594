import EmptyState from "../static/emptystate.png";
import { VscEmptyWindow } from "react-icons/vsc";
export default function Emptystate(props) {
  return (
    <div className="container">
      <div className="p-10 border d-flex flex-column align-items-center">
        <p className="h1">
          <VscEmptyWindow />
        </p>
        {props.children}
      </div>
    </div>
  );
}
