import React from "react";
import { Layout, theme } from "antd";
import Navbar from "../../containers/navbar";
const { Content, Sider, Footer } = Layout;

const LayoutCmp = (props) => {
  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Navbar />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          className="site-layout"
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
            minHeight: "90vh",
            backgroundColor: "#ffffff",
          }}
        >
          {props.children}
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          CvGenius © 2023 Created by DigitalSurge S.A.R.L
        </Footer>
      </Layout>
    </Layout>
  );
};
export default LayoutCmp;
