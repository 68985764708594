import { Fragment, useState, useEffect } from "react";
import Navbar from "../../containers/navbar";
import Header from "../../components/header";
import { useApi } from "../../hooks/useApi";
import qs from "query-string";
import Pagination from "react-js-pagination";
import Req from "../../functions/request";
import { Link } from "react-router-dom";
import QRCode from "qrcode";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import Emptystate from "../../components/emptystate";
import constants from "../../constants/api";
import { Skeleton } from "antd";
import { Dropdown, DropdownButton, Button } from "react-bootstrap";
import Placeholder from "../../static/palceholder-cv.png";
export default function Resumes(props) {
  const [page, setPage] = useState(1);
  const [resumes, setResumes] = useState([]);
  const [data, isLoading, loaded, error] = useApi(
    "/resume/paginate?" +
      qs.stringify({
        size: 10,
        page: 1,
        limit: 10,
      })
  );

  useEffect(async () => {
    if (data?.data) {
      let nr = await Promise.all(
        data.data.map(async (r) => {
          r.qr = await QRCode.toDataURL(`/creator/resume?id=${r.uuid}`);
          r.url = `/creator/resume?id=${r.uuid}`;
          return r;
        })
      );
      setResumes(nr);
    }
  }, [data]);
  useEffect(async () => {
    const { data } = await Req(
      "/resume/paginate?",
      qs.stringify({
        size: 10,
        page: page,
        limit: 10,
      })
    );
    if (data?.data) {
      let nr = await Promise.all(
        data.data.map(async (r) => {
          r.qr = await QRCode.toDataURL(
            `${constants.APP_BASE_URL}/show/${r.uuid}`
          );
          r.preview = `${constants.APP_BASE_URL}/show/${r.uuid}`;

          r.url = `/creator/resume?id=${r.uuid}`;
          return r;
        })
      );
      setResumes(nr);
    }
  }, [page]);
  function HandlePageChange(page) {
    setPage(page);
  }
  return (
    <Fragment>
      <Header title="My Resumes">
        <div className="col-md-6 col-12 text-md-end">
          <div className="mx-n1">
            <Link
              to="/creator"
              className="btn d-inline-flex btn-sm btn-primary mx-1"
            >
              <span className=" pe-2">
                <i className="bi bi-plus" />
              </span>
              <span>New Resume</span>
            </Link>
          </div>
        </div>
      </Header>
      <div className="resumes container mt-3">
        <div className="row">
          {isLoading ? (
            <Spinner className="d-flex align-self-center" animation="grow" />
          ) : (
            ""
          )}

          {isLoading ? (
            <Skeleton
              count="4"
              className="md-col-4"
              style={{
                display: "block",
              }}
            />
          ) : (
            ""
          )}

          {resumes?.length === 0 ? (
            <div className="mt-6 mb-4">
              <Emptystate>
                <h1>You have no resume yet</h1>
                <p>
                  Create a new resume using our builder by clicking button
                  bellow
                </p>
                <Link className="btn btn-sm mt-2  btn-primary" to="/creator">
                  Create a resume
                </Link>
              </Emptystate>
            </div>
          ) : (
            ""
          )}
          {(() => {
            return resumes?.map((r, i) => {
              return (
                <div className="col-md-3" key={i}>
                  <Link to={r.url}>
                    <div className="card">
                      <div className="p-2">
                        <img
                          alt="..."
                          src={r.screenshot ? r.screenshot : Placeholder}
                          className="card-img"
                        />
                      </div>
                      <div className="card-body pb-4">
                        <div className="d-flex">
                          <div className="flex-fill">
                            <h5 className="mb-1">{r.title}</h5>
                            {/* Stats */}
                            <div className="d-flex mt-3 mx-n4">
                              <div className="text-xs font-semibold text-muted mx-4 my-2">
                                <i className="bi bi-calendar me-1" />
                                {moment(r.created_at).format("ddd .Do")}
                              </div>
                              <div className="text-xs font-semibold text-muted mx-4 my-2">
                                <i className="bi bi-clock me-1" />
                                {moment(r.created_at).format("hh:mm")}
                              </div>
                            </div>
                          </div>
                          <div className="ms-auto"></div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            });
          })()}
        </div>
      </div>
      <div className="divider"></div>
      <div className="container mt-8">
        {resumes?.length > 0 ? (
          <div className="p-2">
            <Pagination
              activePage={page}
              itemsCountPerPage={10}
              totalItemsCount={data?.total || 0}
              pageRangeDisplayed={5}
              onChange={HandlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
}
