import { Fragment, memo } from "react";
import RenderName from "./components/name";
import RenderEducation from "./components/education";
import RenderContact from "./components/contact";
import RenderBiography from "./components/biography";
import RenderExperience from "./components/currentExperience";
import RenderTemplate from "./components/templates";
import RenderHobbies from "./components/hobbies";
import RenderSkills from "./components/skills";
import Account from "./components/account";

const steps = {
  biography: {
    title: "Personal Info",
    icon: "fa fa-user",
    key: "biography",
    steps: [RenderName, RenderContact],
  },
  education: {
    title: "Education",
    key: "education",
    icon: "fa fa-graduation-cap",
    steps: [RenderEducation],
    skipAllowed: true,
  },
  experience: {
    title: "Experience",
    icon: "fa fa-suitcase",
    key: "experience",
    steps: [RenderExperience],
    skipAllowed: true,
  },
  biographyText: {
    key: "biography",
    icon: "fa fa-address-book",
    title: "About me",
    skipAllowed: true,
    steps: [RenderSkills, RenderHobbies, RenderBiography],
    transformers: [
      (skills) => {
        let skillsNames = Object.keys(skills).filter((s) =>
          s.startsWith("skill")
        );
        let skillsResult = skillsNames
          .filter((sk) => sk && skills[sk])
          .map((sk) => {
            let skillName = skills[sk];
            let skillLevel = skills[`level-${sk.split("-")[1]}`];

            return {
              skillName,
              skillLevel,
            };
          });
        return skillsResult;
      },
      (hobbies) => {
        return Object.keys(hobbies)
          .map((h) => hobbies[h])
          .filter((h) => h);
      },
    ],
  },
  template: {
    icon: "fa fa-sticky-note-o",
    key: "template",
    title: "Template",
    steps: [RenderTemplate],
  },
  password: {
    icon: "fa fa-sign-in",
    key: "password",
    title: "Confirm",
    hideContinue: true,
    steps: [Account],
  },
};

function Render({ data, refs, next, eventEmitter }) {
  const stepsRendered = refs.map((ref) => {
    const [meta, renderers] = ref;
    const { stepKey } = meta;
    const { steps: schemaSteps } = steps[stepKey];
    return (
      <Fragment key={stepKey}>
        {renderers.map((renderer, index) => {
          let StepRenderer = schemaSteps[index];
          return (
            <div class="row justify-content-center mt-0">
              <div class="col-12 col-lg-12 text-center">
                <div class="mt-3 mb-5">
                  <div class="row">
                    <div class="col-md-12 mx-0">
                      <section
                        ref={renderer.stepRef}
                        class="start-modal-body m-auto"
                        key={`${stepKey}-${index}`}
                      >
                        <div class="container">
                          <div class="row justify-content-md-center">
                            <div class="col-md-6">
                              <StepRenderer
                                next={next}
                                eventEmitter={eventEmitter}
                                data={data}
                                renderer={renderer}
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  });

  return stepsRendered;
}

export { steps, Render };
