import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import { UserProvider } from "./contexts/User";
import { ConfigProvider } from "antd";
import AuthMiddleware from "./utils/authMiddleware";
import {
  useTranslation,
  initReactI18next,
  I18nextProvider,
} from "react-i18next";
import i18n from "i18next";
import Signin from "./screens/signin";
import Singup from "./screens/signup";
import Home from "./screens/home";
import Profile from "./screens/profile";
import ForgotPwd from "./screens/forgotpwd";
import Invitations from "./screens/invitations";
import Creator from "./screens/creator";
import Resumes from "./screens/resumes";
import Start from "./screens/start";
import Layout from "./components/Layout";
import Scanner from "./screens/Scanner";
import "antd/dist/reset.css";
import "./App.scss";

function App() {
  useEffect(() => {
    i18n.use(initReactI18next).init({
      resources: {
        en: {
          translation: require("./locales/en/translation.json"),
        },
        fr: {
          translation: require("./locales/fr/translation.json"),
        },
      },
      lng: "en",
      fallbackLng: "en",
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false,
      },
    });
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <UserProvider>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#59BDB3",
              borderRadius: 4,
              fontSize: 14,
            },
          }}
        >
          <Router>
            <Routes>
              <Route path="/signin" element={<Signin />}></Route>{" "}
              <Route path="/signup" element={<Singup />}></Route>
              <Route path="/start" element={<Start />} />
              <Route
                path="/"
                element={
                  <AuthMiddleware>
                    <Layout>
                      <Home />
                    </Layout>
                  </AuthMiddleware>
                }
              ></Route>
              <Route
                path="/scanner"
                element={
                  <AuthMiddleware>
                    <Layout>
                      <Scanner />
                    </Layout>
                  </AuthMiddleware>
                }
              ></Route>
              <Route
                path="/profile"
                element={
                  <AuthMiddleware>
                    <Layout>
                      <Profile />
                    </Layout>
                  </AuthMiddleware>
                }
              ></Route>
              <Route
                path="/resumes"
                element={
                  <AuthMiddleware>
                    <Layout>
                      <Resumes />
                    </Layout>
                  </AuthMiddleware>
                }
              ></Route>
              <Route
                path="/invitations"
                element={
                  <AuthMiddleware>
                    <Layout>
                      <Invitations />
                    </Layout>
                  </AuthMiddleware>
                }
              ></Route>
              <Route path="/creator/*" element={<Creator />} />
              <Route path="/forgot_password" element={<ForgotPwd />} />
            </Routes>
          </Router>
        </ConfigProvider>
      </UserProvider>
    </I18nextProvider>
  );
}

export default App;
