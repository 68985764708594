import React from "react";
import ReactPDFHtml from "react-pdf-html";
import { View, Text } from "@react-pdf/renderer";
import { DateRange } from "../DateRange";
import { useTranslation } from "react-i18next";

export function Education({ education, styles }) {
  const { t } = useTranslation();

  return (
    <View style={[styles.section]}>
      <Text style={styles.sectionTitle}>{t("t_education")}</Text>
      {education.map((edu) => (
        <React.Fragment key={edu.id}>
          <View style={styles.sectionInfo}>
            <Text style={[styles.sectionSubtitle, styles.h4]}>
              {edu.school}
            </Text>
            <Text style={styles.sectionSubtitle}>{edu.title}</Text>
          </View>
          <DateRange
            size={13}
            color={"black"}
            style={styles.text}
            startMonth={edu.start_month}
            startYear={edu.start_year}
            endMonth={edu.end_month}
            endYear={edu.end_year}
          />
          <View style={styles.description}>
            <ReactPDFHtml
              stylesheet={styles.html}
              collapse={false}
              resetStyles={false}
            >
              {edu.description}
            </ReactPDFHtml>
          </View>
        </React.Fragment>
      ))}
    </View>
  );
}
