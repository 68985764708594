import { Fragment } from "react";

export function InfoBox(props) {
  return (
    <Fragment>
      <div className={"alert alert-" + props.type} role="alert">
        {props.text}
      </div>
    </Fragment>
  );
}
