import { useEffect, useState, Fragment, useContext } from "react";
import logo from "../../static/logo.png";
import { useAuth } from "../../hooks/useAuth";

import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  getIdToken,
} from "firebase/auth";
import { auth, providerGoogle, providerMicrosoft } from "../../firebase";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Alert } from "antd";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .min(6, "Must be at least 6 characters")
    .required("Required"),
});

export default function Signin() {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmitClick(formik.values);
    },
  });

  const [state, setState] = useState({
    email: "",
    password: "",
    message: null,
    error: null,
  });
  const [apiError, setApiError] = useState(null);

  const [submit, setSubmitted] = useState(false);
  const [data, loading, error, AuthFunction] = useAuth();
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  function getErrorMessage(code) {
    switch (code) {
      case "auth/user-not-found":
        return "User not found";
      case "auth/wrong-password":
        return "Wrong password";
      case "auth/invalid-email":
        return "Invalid email";
      default:
        return "An error occurred";
    }
  }
  useEffect(() => {
    const auth = window.localStorage.getItem("Authorization");
    if (auth) {
      window.location.href = "/";
    }
  }, []);
  useEffect(
    function () {
      (async function () {
        if (submit) {
          try {
            const userCredential = await signInWithEmailAndPassword(
              auth,
              state.email,
              state.password
            );
            const user = userCredential.user;

            const idToken = await getIdToken(user);
            await AuthFunction({
              email: state.email,
              full_name: state.displayName,
              password: state.password,
              idToken,
            });
          } catch (error) {
            setApiError(getErrorMessage(error.code));
          }
          setSubmitted(false);
        }
      })();
    },
    [submit, state.email, state.password, AuthFunction]
  );

  function handleSubmitClick() {
    const values = formik.values;
    setState((prevState) => ({
      ...prevState,
      email: values.email,
      password: values.password,
    }));
    setSubmitted(!submit);
  }

  const handleGoogleSignIn = async () => {
    try {
      const userCredential = await signInWithPopup(auth, providerGoogle);
      const user = userCredential.user;
      console.log(user);
      setState((prevState) => ({
        ...prevState,
        full_name: user.displayName,
        profile_pic: user.photoURL,
        email: user.email,
      }));
      const idToken = await getIdToken(user);

      await AuthFunction({
        email: user.email,
        full_name: user.displayName,
        profile_pic: user.photoURL,
        idToken,
      });
    } catch (error) {
      setApiError("Error logging in with Google:");
    }
  };

  const handleMicrosoftSignIn = async () => {
    try {
      const userCredential = await signInWithPopup(auth, providerMicrosoft);
      const user = userCredential.user;
      console.log(user);
      formik.setFieldValue("email", user.email);
      const idToken = await getIdToken(user);

      await AuthFunction({
        email: user.email,
        idToken,
        full_name: user.displayName,
        profile_pic: user.photoURL,
      });
    } catch (error) {
      setApiError("Error logging in with Microsoft:");
    }
  };

  return (
    <div className="authContainer">
      <div className="px-5 py-5 p-lg-0 bg-gradient-primary">
        <div className="d-flex justify-content-center">
          <div className="col-12 col-md-9 col-lg-7 offset-lg-3 border-left-lg min-h-lg-screen d-flex flex-column justify-content-center py-lg-16 px-lg-20 position-relative">
            <div className="row">
              <div className="col-lg-7 col-md-10 col-xl-6 mx-auto ms-xl-0">
                <div className="mt-10 mt-lg-10 mb-6 d-flex align-items-center d-lg-block">
                  <div className="mx-auto">
                    <img
                      className="mb-4 d-flex"
                      src={logo}
                      alt=""
                      width="172"
                      height="157"
                    />
                  </div>
                  <span className="d-inline-block d-lg-block h1 mb-lg-6 me-3">
                    <div className="gradient"></div>
                    👋
                  </span>
                  <h1 className="ls-tight font-bolder h2">Nice to see you!</h1>
                  <h3 className="ls-tight h3">
                    Signin with your google or microsoft account
                  </h3>
                </div>
                <div className="third-party-signup mb-3 d-flex justify-content-center">
                  <Button
                    variant="primary"
                    className="m-1 w-100"
                    onClick={handleGoogleSignIn}
                  >
                    <i className="bi bi-google"></i>
                    <span style={{ marginLeft: "10px", color: "white" }}>
                      Google
                    </span>
                  </Button>
                  <Button
                    variant="primary"
                    className="m-1 w-100"
                    onClick={handleMicrosoftSignIn}
                  >
                    <i className="bi bi-microsoft"></i>
                    <span style={{ marginLeft: "10px", color: "white" }}>
                      Microsoft
                    </span>
                  </Button>
                </div>

                <div className="py-5 text-center">
                  <span className="text-xs text-uppercase font-semibold">
                    or
                  </span>
                </div>
                <div className="row">
                  <div className="text-center mt-4" style={{ color: "white" }}>
                    <p className="mb-0">
                      Already have an account? <Link to="/signup">Sign In</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
