import { View } from "@react-pdf/renderer";
import { Icon } from "../Icon";

export function Score({ id, rank, primaryColor, lightColor }) {
  return (
    <View style={{ flexDirection: "row" }}>
      {Array.from({ length: 5 }).map((_, index) => (
        <Icon
          key={`${id}-${index}`}
          size={7.5}
          name="dot"
          style={{ marginRight: 1 }}
          color={index <= rank - 1 ? primaryColor : lightColor}
        />
      ))}
    </View>
  );
}
