import { ResumeProvider } from "../../contexts/resume";

import Templates from "../../containers/templates";
import Resume from "../../containers/resume";
import { Routes, Route, useParams, Link, Navigate } from "react-router-dom";

export default function Creator() {
  let { path } = useParams();

  return (
    <ResumeProvider>
      <Routes>
        <Route path={`resume`} element={<Resume />} />
        <Route path={``} element={<Templates />} />
      </Routes>
    </ResumeProvider>
  );
}
