import { Nav } from "react-bootstrap";
import {
  BsPerson,
  BsBriefcase,
  BsBook,
  BsBox,
  BsChevronDown,
} from "react-icons/bs";

import { IoLanguage, IoStarOutline } from "react-icons/io5";
import "./NavigationBar.scss";
import { MdDragIndicator } from "react-icons/md";
import { useTranslation } from "react-i18next";

function NavigationBar({ setIsOpen }) {
  const { t } = useTranslation();
  return (
    <Nav className="flex-column nav-light">
      <Nav.Link className="nav-link-light " onClick={() => setIsOpen(1)}>
        <div>
          <BsPerson className="nav-icon" /> {t("t_personal_info")}
        </div>
        <MdDragIndicator />
      </Nav.Link>

      <Nav.Link className="nav-link-light " onClick={() => setIsOpen(2)}>
        <div>
          <BsBook className="nav-icon" /> {t("t_education")}
        </div>
        <MdDragIndicator />
      </Nav.Link>
      <Nav.Link className="nav-link-light " onClick={() => setIsOpen(3)}>
        <div>
          {" "}
          <BsBriefcase className="nav-icon" /> {t("t_experience")}
        </div>

        <MdDragIndicator />
      </Nav.Link>
      <Nav.Link className="nav-link-light " onClick={() => setIsOpen(4)}>
        <div>
          {" "}
          <BsBox className="nav-icon" />
          {t("t_skills")}
        </div>
        <MdDragIndicator />
      </Nav.Link>
      <Nav.Link className="nav-link-light " onClick={() => setIsOpen(5)}>
        <div>
          {" "}
          <IoStarOutline className="nav-icon" />
          {t("t_interests")}
        </div>

        <MdDragIndicator />
      </Nav.Link>
      <Nav.Link className="nav-link-light " onClick={() => setIsOpen(6)}>
        <div>
          {" "}
          <IoLanguage className="nav-icon" />
          {t("t_languages")}
        </div>
        <MdDragIndicator />
      </Nav.Link>
    </Nav>
  );
}
export default NavigationBar;
