import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ResumeContext } from "../contexts/resume";
import { useForm } from "../hooks/useForm";
export default function CreatorNav() {
  const [resume, dispatch] = useContext(ResumeContext);
  const [resumeData, loading, loaded, error, request] = useForm();
  const [submitR, setSubmitR] = useState(false);
  const [cvname, setCvname] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      if (submitR) {
        await request("/resume/create", {
          template_id: resume.template,
          title: cvname,
        });
      }
    })();
  }, [submitR]);
  useEffect(() => {
    if (resumeData) {
      dispatch(
        {
          type: "CREATE_RESUME",
          payload: { resume: { name: cvname, id: resumeData.id } },
        },
        resume
      );
      setSubmitR(false);
      navigate(`resume?id=${resumeData.id}`);
    }
  }, [resumeData]);
  function createResume() {
    if (resume.template && cvname != "") {
      setSubmitR(true);
    }
  }

  function resumeNameHandler(e) {
    const { value } = e.target;
    setCvname(value);
    dispatch(
      { type: "CREATE_RESUME", payload: { resume: { name: cvname } } },
      resume
    );
  }
  return (
    <nav
      className="navbar navbar-white bg-white border-top h-sm-18 px-0 py-md-0"
      style={{ zIndex: "1000" }}
    >
      <div className="container">
        {/* Right menu */}
        <div className="navbar-user d-flex flex-fill flex-md-none order-md-2">
          {resume?.template && resume?.resume !== "" && !resumeData ? (
            <div className="ms-auto ms-md-5">
              <a onClick={createResume} className="btn btn-primary">
                {!submitR ? (
                  <i className="bi bi-arrow-right"></i>
                ) : (
                  <div className="spinner-grow spinner-grow-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </a>
            </div>
          ) : (
            ""
          )}
        </div>

        {/* Breadcrumbs */}
        <nav className="nav d-flex mx-n4 text-sm lh-none overflow-x order-md-1 mt-5 mt-sm-0">
          <input
            type="text"
            value={cvname}
            onChange={resumeNameHandler}
            className="form-control order-md-2"
            placeholder="Resume Name"
          />
        </nav>
      </div>
    </nav>
  );
}
