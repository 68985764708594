import Navbar from "../../containers/navbar";
import Header from "../../components/header";
import { Fragment, useEffect, useState, useContext } from "react";
import { useApi } from "../../hooks/useApi";
import StatsOne from "../../components/statsOne";
import ListGroup from "../../components/ListGroup";
import Card from "../../components/Card";
import { useForm } from "../../hooks/useForm";
import { InfoBox } from "../../components/InfoBox";
import validations from "../../utils/validations";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Invitations() {
  const [data, isLoading, loaded, error] = useApi("/invitations/fetch");
  const [email, setEmail] = useState("");
  const [submit, setSubmit] = useState(false);
  const [invite, isLoadingf, loadedf, errorf, request] = useForm();

  useEffect(() => {
    (async function () {
      if (submit) {
        await request("/invitations/create", { email });

        setSubmit(false);
      }
    })();
  }, [submit, data]);
  function inputChange({ target }) {
    let { value } = target;

    setEmail(value);
  }
  function addInvite() {
    validations.addInvitationSchema.cast({ email });
    setSubmit(true);
  }

  return (
    <Fragment>
      <Header title="Invitations">
        <div className="col-md-6 col-12 text-md-end">
          <div className="mx-n1">
            <a
              href="#"
              className="btn d-inline-flex btn-sm btn-neutral border-base mx-1"
            >
              <span className=" pe-2">
                <i className="bi bi-pencil" />
              </span>
              <span>Edit</span>
            </a>
            <a href="#" className="btn d-inline-flex btn-sm btn-primary mx-1">
              <span className=" pe-2">
                <i className="bi bi-plus" />
              </span>
              <span>Create</span>
            </a>
          </div>
        </div>
      </Header>
      <div className="container">
        <div className="row mt-4">
          {errorf ? <InfoBox type="danger" text={errorf.message} /> : ""}
          {error ? <InfoBox type="danger" text={error.message} /> : ""}

          <div className="col-md-6 col-12 p-y20">
            <StatsOne
              percentage={data?.progress}
              title="Goal Progress"
            ></StatsOne>
            <h3 className="mt-4">Accepted Invitations:</h3>
            {loaded ? (
              <ListGroup list={data?.accepted_invites}></ListGroup>
            ) : (
              <Skeleton count={5} />
            )}
            <h3 className="mt-4">Pending Invitations:</h3>
            {loaded ? (
              <ListGroup list={data?.pending_invites}></ListGroup>
            ) : (
              <Skeleton count={5} />
            )}
          </div>
          <div className="col-md-6 col-12 p-y20">
            <Card title="Refer Your Friend and get great perks!">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries,
              </p>
            </Card>
            <Card title="Create a new invitation">
              <div className="row mb-5">
                <div className="col-md-9">
                  <label className="form-label" htmlFor="firstname">
                    Email
                  </label>
                  <input
                    type="text"
                    onChange={inputChange}
                    className={
                      isLoadingf ? "form-control" : "form-control disabled"
                    }
                    id="email"
                    value={email}
                  />
                </div>
                <div className="col-md-3">
                  <div className="mt-8">
                    <a
                      onClick={addInvite}
                      className={
                        !isLoadingf
                          ? "btn btn-md btn-primary"
                          : "btn btn-md btn-primary disabled"
                      }
                    >
                      {!isLoadingf ? (
                        ""
                      ) : (
                        <div
                          className="spinner-border-sm text-light"
                          role="status"
                        ></div>
                      )}
                      Invite
                    </a>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
