export default function Card(props) {
  return (
    <div className="card mt-4">
      <div className="card-header d-flex align-items-center">
        {/* Title */}
        <h5>{props.title}</h5>
      </div>
      <div className="card-body">
        {/* Placeholder */}
        <span className="d-block h-40 border border-2 border-dashed rounded p-5">
          {props.children}
        </span>
      </div>
    </div>
  );
}
