import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { TextIcon } from "../IconText";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    marginLeft: 4,
  },
});

export function DateRange({
  style,
  startYear,
  startMonth,
  endYear,
  endMonth,
  color = "black",
  size = 10,
}) {
  const dateRangeText = `${startMonth}/${startYear} - ${endMonth}/${endYear}`;
  return (
    <View style={[styles.container, style]}>
      <TextIcon
        color={color}
        size={size}
        text={dateRangeText}
        iconName="calendar"
      />
    </View>
  );
}
