import { Formik, Form, Field } from "formik";
import { Fragment } from "react";
import * as Yup from "yup";

export default function BiographyText(props) {
  const { ref, formRef } = props.renderer;

  const validationSchema = Yup.object({
    biographyText: Yup.string()
      .required()
      .min(40, "need to be a little bit longer (40 character)"),
  });

  return (
    <Fragment>
      <div class="titlecls">More about you!</div>
      <Formik
        innerRef={formRef}
        validationSchema={validationSchema}
        validateOnChange={true}
        initialValues={{}}
        isInitialValid={false}
      >
        {({ values }) => (
          <Form>
            <Field
              name="biographyText"
              value={values.biographyText}
              as="textarea"
            >
              {({ field, form: { errors } }) => (
                <textarea
                  className={
                    "form-control text-primary text-md " +
                    `${errors.biographyText ? "border-danger is-invalid" : ""}`
                  }
                  id="biographyText"
                  ref={ref.issue("biographyText")}
                  {...field}
                  placeholder="Briefly describe your self"
                ></textarea>
              )}
            </Field>
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6"></div>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}
