import {
  PlusOutlined,
  LoadingOutlined,
  EyeOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Upload, message } from "antd";
import { useState } from "react";
import constants from "../constants/api";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const UploadProfile = ({ value, onValueChange }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(value);

  const jwtToken = localStorage.getItem("Authorization");
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);

      const publicUrl = info.file.response.publicUrl;

      onValueChange(publicUrl);

      setImageUrl(`data:image/jpeg;base64,${info.file.response.base64Image}`);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader w-100"
        showUploadList={false}
        action={`${constants.API_BASE_URL}/upload/profile`}
        headers={{ Authorization: `Bearer ${jwtToken}` }}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {value ? (
          <img src={value} alt="avatar" className="w-100" />
        ) : (
          uploadButton
        )}
      </Upload>
    </>
  );
};

export default UploadProfile;
