import { useState } from "react";
import UploadProfile from "./UploadProfile";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles
function CreatorUserInfo({ present, updateUser }) {
  console.log("user info", present);
  const [showEmailPhone, setShowEmailPhone] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="p-1 step-1">
      <div className="row">
        <div className="col-md-2 col-4">
          <label className="form-label" htmlFor="phone">
            {t("your_pic")}:
          </label>
          <div className="d-flex justify-content-end">
            <div className="w-100">
              <UploadProfile
                value={present?.user?.profile_pic}
                onValueChange={(url) => {
                  updateUser({
                    target: {
                      id: "profile_pic",
                      value: url,
                      dataset: {
                        type: "user",
                      },
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-10 col-8">
          <div className="row">
            <div className="col-md-6">
              <div className="">
                <label className="form-label" htmlFor="firstname">
                  {t("first_name")}:
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={updateUser}
                  value={present?.user?.firstname}
                  data-type="user"
                  id="firstname"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="">
                <label className="form-label" htmlFor="lastname">
                  {t("last_name")}:
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={updateUser}
                  value={present?.user?.lastname}
                  data-type="user"
                  id="lastname"
                />
              </div>
            </div>
            {showEmailPhone && (
              <>
                <div className="col-md-6 d-block d-sm-none">
                  <div className="">
                    <label className="form-label" htmlFor="email">
                      {t("t_email")}:
                    </label>
                    <input
                      type="email"
                      value={present?.user?.email}
                      onChange={updateUser}
                      className="form-control"
                      id="email"
                      data-type="user"
                    />
                  </div>
                </div>
                <div className="col-md-6 d-block d-sm-none">
                  <div className="">
                    <label className="form-label" htmlFor="phone">
                      {t("phone")}:
                    </label>
                    <input
                      type="text"
                      value={present?.user?.phone}
                      onChange={updateUser}
                      className="form-control"
                      id="phone"
                      data-type="user"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="col-md-6 d-none d-sm-block">
              <div className="">
                <label className="form-label" htmlFor="email">
                  {t("t_email")}:
                </label>
                <input
                  type="email"
                  value={present?.user?.email}
                  onChange={updateUser}
                  className="form-control"
                  id="email"
                  data-type="user"
                />
              </div>
            </div>
            <div className="col-md-6 d-none d-sm-block">
              <div className="">
                <label className="form-label" htmlFor="phone">
                  {t("phone")}:
                </label>
                <input
                  type="text"
                  value={present?.user?.phone}
                  onChange={updateUser}
                  className="form-control"
                  data-type="user"
                  id="phone"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="">
            <label className="form-label" htmlFor="address">
              {t("t_title")}:
            </label>
            <input
              type="text"
              value={present.user?.title}
              onChange={updateUser}
              className="form-control"
              id="title"
              data-type="user"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatorUserInfo;
