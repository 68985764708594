import React, { useEffect, useState } from "react";
import {
  Card,
  Steps,
  Alert,
  Spin,
  Row,
  Col,
  Button,
  Upload,
  Input,
} from "antd";

import { useDropzone } from "react-dropzone";
import { BsFileEarmarkText } from "react-icons/bs";
import ProcessingResult from "../ProcessingResults";
import { useForm } from "../../hooks/useForm";
import ScanItems from "../../components/ScanItem";
const { Step } = Steps;
const { TextArea } = Input;
export default () => {
  const [file, setFile] = useState(null);
  const [coverLetter, setCoverLetter] = useState("");
  const [jobPost, setJobPost] = useState("");
  const [uploading, setUploading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [result, setResult] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [atsRequestDone, setAtsRequestDone] = useState(false);
  const [scanData, setScanData] = useState([]);
  const [data, loading, loaded, error, request] = useForm();
  const [pdfUrl, setPdfUrl] = useState(null);
  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  const handleATSRequest = async (pdfUrl) => {
    setProcessing(true);

    try {
      const scannerPayload = {
        pdfUrl: pdfUrl,
        coverLetter,
        jobPost,
      };
      console.log(scannerPayload);
      const resp = await request("/ats/scanner", scannerPayload);
      console.log(resp);
      setAtsRequestDone(true);
      setResult(resp.atsResponse);
      setProcessing(false);
    } catch (error) {
      console.error(error);
      setProcessing(false);
    }
  };

  const handleUpload = async () => {
    setUploading(true);

    // Upload the file and process it.
    // For the sake of simplicity, we simulate the upload and processing process with a setTimeout.
    setTimeout(async () => {
      setUploading(false);

      const formData = new FormData();
      formData.append("file", file);

      try {
        const uploadResponse = await request("/scanner/upload", formData);
        console.log(data);
        setPdfUrl(uploadResponse);
      } catch (error) {
        console.error(error);
      }
    }, 2000);
  };

  useEffect(async () => {
    if (pdfUrl) {
      await handleATSRequest(pdfUrl);
      setPdfUrl(null); // Reset pdfUrl to prevent re-running
    }
  }, [pdfUrl]);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="mt-4">
            <Upload.Dragger
              accept=".pdf"
              showUploadList={false}
              beforeUpload={(file) => {
                onDrop([file]);
                return false;
              }}
            >
              {isDragActive ? (
                <p>Drop the file here...</p>
              ) : (
                <>
                  <BsFileEarmarkText size={90} />
                  <h4 className="mt-6">
                    Drag and drop your CV here, or click to select a file
                  </h4>
                </>
              )}
            </Upload.Dragger>
            {file && (
              <Alert
                message={file.name}
                type="info"
                style={{ marginTop: "1rem" }}
              />
            )}
            <div className="mt-3">
              <Button type="primary" onClick={nextStep} disabled={!file}>
                Next
              </Button>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="mt-4">
            <label htmlFor="coverLetter">Cover Letter:</label>
            <TextArea
              id="coverLetter"
              rows={20}
              value={coverLetter}
              onChange={(e) => setCoverLetter(e.target.value)}
            />
            <div className="mt-3">
              <Button className="mr-2" onClick={previousStep}>
                Back
              </Button>
              <Button type="primary" onClick={nextStep} disabled={!coverLetter}>
                Next
              </Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="mt-4">
            <label htmlFor="jobPost">Job Post:</label>
            <TextArea
              id="jobPost"
              rows={20}
              value={jobPost}
              onChange={(e) => setJobPost(e.target.value)}
            />
            <div className="mt-3">
              <Button className="mr-2" onClick={previousStep}>
                Back
              </Button>
              <Button
                type="primary"
                onClick={handleUpload}
                disabled={uploading || processing || !jobPost}
              >
                Upload
              </Button>
            </div>
          </div>
        );
      default:
        return <div>Invalid step</div>;
    }
  };

  return (
    <div className="">
      {!result && (
        <Row justify="center" style={{ marginTop: "1rem" }}>
          <Col span={20}>
            <Card title="Upload your CV, Cover Letter, and Job Post">
              <Steps current={currentStep} size="default">
                <Step title="Upload CV" />
                <Step title="Cover Letter" />
                <Step title="Job Post" />
              </Steps>
              {renderStepContent(currentStep)}

              {uploading && (
                <Alert
                  message="Uploading CV, Cover Letter, and Job Post..."
                  type="info"
                  showIcon
                  icon={<Spin size="small" />}
                  style={{ marginTop: "2rem" }}
                />
              )}
              {processing && (
                <Alert
                  message="Processing CV , Cover Letter, and Job Post..."
                  type="warning"
                  showIcon
                  icon={<Spin size="small" />}
                  style={{ marginTop: "2rem" }}
                />
              )}
            </Card>
          </Col>
        </Row>
      )}
      {result && <ProcessingResult result={result} />}
    </div>
  );
};
