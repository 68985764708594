import { View, StyleSheet } from "@react-pdf/renderer";
import { Icon } from "../Icon";
const { Link } = require("@react-pdf/renderer");
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  link: {
    marginLeft: 4,
  },
});

export function LinkIcon({ style, color, url, iconName, size = 10 }) {
  return (
    <View style={[styles.container, style]}>
      <Icon color={color} size={size} name={iconName} />
      <Link style={styles.link} src={url}>
        {url}
      </Link>
    </View>
  );
}
