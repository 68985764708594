import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Navigate } from "react-router-dom";
import constants from "../constants/api";
axios.defaults.baseURL = constants.API_BASE_URL;
export function useAuth() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoding] = useState(false);
  let navigate = useNavigate();

  const AuthFunction = (payload) => {
    setLoding(true);

    return axios
      .post("/user/login", payload)
      .then(({ data }) => {
        console.log(data);
        localStorage.setItem("Authorization", data.token);
        window.location.href = "/";
        setData(data);
        setLoding(false);
      })
      .catch((error) => {
        let err = new Error(error);
        setError(err);
        setLoding(false);
      });
  };
  return [data, loading, error, AuthFunction];
}
