import React from "react";
import { Button } from "react-bootstrap";
import { IoArrowBackOutline } from "react-icons/io5";
import "./CollapsableSection.scss";
import { useTranslation } from "react-i18next";
import Education from "./Education";
import { Experience } from "./Experience";
import Skills from "./Skills";
import Interests from "./Intrests";
import PersonalInfo from "./PersonalInfo";
import Languages from "./Languages";
function getView(type) {
  switch (type) {
    case "education":
      return Education;
    case "experiences":
      return Experience;
    case "skills":
      return Skills;
    case "interests":
      return Interests;
    case "personal_info":
      return PersonalInfo;
    case "languages":
      return Languages;
    default:
      return Education;
  }
}

const CollapsableSection = ({
  title,
  icon,
  children,
  isOpen,
  setIsOpen,
  id,
  dataType,
  addItem,
  updateItem,
  deleteItem,
  items,
}) => {
  const { t } = useTranslation();
  const goBack = () => {
    setIsOpen(null);
  };
  const View = getView(dataType);

  return (
    <div className={`mb-4${isOpen ? "" : " d-none"}  `}>
      <div className=" ">
        <div className="section-header d-flex justify-content-between align-items-center">
          <Button
            variant="secondary"
            onClick={goBack}
            className="text-dark btn  btn-secondary"
          >
            <IoArrowBackOutline />
            {t("go_back")}
          </Button>
          <div className="header-title ">
            <h4 className="mb-1 text-dark">{icon} </h4>{" "}
            <h4 className="font-weight-bold  text-dark px-1 mb-1">{title}</h4>
          </div>
          <div className="mx-1">
            <button
              style={{ width: "100%" }}
              className="btn  m-1 btn-primary w-100 btn-block "
              onClick={addItem}
              data-type={dataType}
            >
              {t("add_new")}
              <i className="mx-2  bi bi-plus-square"></i>
            </button>
          </div>
        </div>
        <div className="content-area px-10 w-100" style={{ height: "100%" }}>
          <div className="w-100"></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            {(() => {
              return items?.map((item, i) => {
                return (
                  <View
                    data={item}
                    delete={deleteItem}
                    dkey={i}
                    key={i}
                    handler={updateItem}
                  />
                );
              });
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollapsableSection;
