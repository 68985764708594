import React, { useEffect, useRef, useState } from "react";
import * as pdfjsLib from "pdfjs-dist";
import { debounce } from "lodash";
import { useForm } from "../../hooks/useForm";
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
const PDFPreviewCanvas = React.memo(({ updateForm, pdfDoc, font, color }) => {
  const canvasRef = useRef();
  const [abortController, setAbortController] = useState(null);
  const [previousRenderTask, setPreviousRenderTask] = useState(null);
  const [data, loading, loaded, error, request] = useForm();
  const renderedPagesCache = new Map();
  const offscreenCanvasRef = useRef();
  const [imageData, setImageData] = useState("");

  useEffect(() => {
    updateForm({
      target: {
        id: "screenshot",
        dataset: {
          type: "resume",
        },
        value: imageData,
      },
    });
  }, [imageData]);

  const renderPDFPage = async (signal) => {
    const pageNumber = 1;
    if (renderedPagesCache.has(pageNumber)) {
      const imageData = renderedPagesCache.get(pageNumber);
      const ctx = canvasRef.current.getContext("2d");
      ctx.putImageData(imageData, 0, 0);
      return;
    }

    if (previousRenderTask) {
      previousRenderTask.cancel();
    }

    const page = await pdfDoc.getPage(pageNumber, { signal });
    const viewport = page.getViewport({ scale: 1, rotation: 0 });

    const containerWidth = canvasRef.current.parentNode.clientWidth;
    const containerHeight = canvasRef.current.parentNode.clientHeight;
    const scale =
      Math.min(
        containerWidth / viewport.width,
        containerHeight / viewport.height
      ) * 4;

    const offscreenCanvas = offscreenCanvasRef.current;
    offscreenCanvas.width = viewport.width * scale;
    offscreenCanvas.height = viewport.height * scale;
    const offscreenCanvasContext = offscreenCanvas.getContext("2d", {
      alpha: false,
    });
    offscreenCanvasContext.setTransform(scale, 0, 0, scale, 0, 0);
    offscreenCanvasContext.imageSmoothingEnabled = true;
    offscreenCanvasContext.imageSmoothingQuality = "high";

    const renderTask = page.render({
      canvasContext: offscreenCanvasContext,
      viewport,
      renderInteractiveForms: false,
    });
    setPreviousRenderTask(renderTask);

    try {
      await renderTask.promise;
      const imageData = offscreenCanvasContext.getImageData(
        0,
        0,
        offscreenCanvas.width,
        offscreenCanvas.height
      );
      renderedPagesCache.set(pageNumber, imageData);
      setPreviousRenderTask(null);

      const canvas = canvasRef.current;
      canvas.width = offscreenCanvas.width;
      canvas.height = offscreenCanvas.height;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(offscreenCanvas, 0, 0);
      const canvasImageData = canvas.toDataURL("image/png");
      setImageData(canvasImageData);
    } catch (error) {
      if (error.name === "RenderingCancelledException") {
        console.log("Rendering cancelled.");
      } else {
        console.error(error);
      }
    }
  };

  const debouncedRenderPDFPage = debounce(renderPDFPage, 500);

  useEffect(() => {
    if (abortController) {
      abortController.abort();
    }
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    debouncedRenderPDFPage(newAbortController.signal);
    return () => {
      newAbortController.abort();
    };
  }, [pdfDoc, font, color]);

  return (
    <>
      <canvas ref={offscreenCanvasRef} style={{ display: "none" }} />
      <canvas
        className="shadow"
        ref={canvasRef}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </>
  );
});

export default React.memo(PDFPreviewCanvas);
