import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Avatar, Dropdown } from "antd";
import {
  FireOutlined,
  FileDoneOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  BugOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useApi } from "../../hooks/useApi";
import { UserContext } from "../../contexts/User";
import Logo from "../../static/logo-icon.png";
import "./Sidebar.css"; // You can define your custom CSS for styling

const { Sider } = Layout;

const Sidebar = () => {
  const [data, isLoading, loaded] = useApi("/profile/fetch");
  const [state, dispatch] = useContext(UserContext);

  useEffect(() => {
    if (loaded && !isLoading) {
      dispatch({ type: "LOGIN_ACTION", payload: data }, state);
    }
  }, [loaded, isLoading]);

  const menu = (
    <Menu>
      <Menu.Item key="profile" icon={<UserOutlined />}>
        <Link to="/profile">My Profile</Link>
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item
        key="logout"
        onClick={() => {
          window.localStorage.removeItem("Authorization");
          window.location.href = "/signin";
        }}
        icon={<LogoutOutlined />}
      >
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Sider width={200} theme="light" className="sidebar">
      <div className="logo">
        <Link to="/">
          <img src={Logo} width="90px" alt="Logo" />
        </Link>
      </div>
      <Menu theme="light" mode="vertical" defaultSelectedKeys={["home"]}>
        <Menu.Item key="home" icon={<HomeOutlined />}>
          <Link to="/">Home</Link>
        </Menu.Item>
        <Menu.Item key="scanner" icon={<FireOutlined />}>
          <Link to="/scanner">CV Scanner</Link>
        </Menu.Item>
        <Menu.Item key="resumes" icon={<FileDoneOutlined />}>
          <Link to="/resumes">My Resumes</Link>
        </Menu.Item>
        <Menu.ItemGroup>
          <Menu.Divider />
          <Menu.ItemGroup title="Support">
            <Menu.Item key="support" icon={<QuestionCircleOutlined />}>
              <a href="https://support.cvgenius.ai/portal/en/kb/cvgenius">
                Support Home
              </a>
            </Menu.Item>
            <Menu.Item key="report-bug" icon={<BugOutlined />}>
              <a href="https://support.cvgenius.ai/portal/en/newticket?departmentId=902382000000006907">
                Report a Bug
              </a>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
        </Menu.ItemGroup>
        <div className="user-profile">
          <Menu.ItemGroup title="">
            <Dropdown overlay={menu} trigger={["click"]}>
              <Avatar
                size={54}
                src={!loaded ? Logo : data?.profile_pic}
                alt=""
              />
            </Dropdown>
          </Menu.ItemGroup>
        </div>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
