import { StyleSheet } from "@react-pdf/renderer";

export const COLORS = {
  gray: "#7f8c8d",
  darkGray: "#4a4a4a",
  white: "#FFFFFF",
};

export const FONT_SIZES = {
  small: 8,
  medium: 12,
  large: 16,
  extraLarge: 20,
};

export const MARGINS = {
  small: 3,
  medium: 10,
};

export default function createStyles(fontFamily, color) {
  return StyleSheet.create({
    image: {
      width: "90px",
      height: "90px",
    },
    imageWrapper: {
      justifyContent: "end",
    },
    container: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: MARGINS.small,
      display: "flex",
      width: "100%",
    },
    paragraph: {
      margin: 0,
    },
    lineBreak: {
      display: "none",
    },
    html: {
      unorderedList: {
        margin: 0,
      },
      ul: {
        margin: 0,
      },
      li: {
        margin: 0,
        fontSize: FONT_SIZES.small,
      },
      listItem: {
        fontSize: FONT_SIZES.small,
        margin: 0,
      },

      p: {
        fontSize: FONT_SIZES.small,
        margin: 0,
      },

      paragraph: {
        fontSize: FONT_SIZES.small,
        margin: 0,
      },
      bold: {
        fontWeight: "bold",
      },
    },
    location: {
      maxWidth: "40px",
    },
    description: {
      fontSize: FONT_SIZES.small,
      marginBottom: MARGINS.small,
      color: COLORS.darkGray,
      fontFamily: fontFamily || "Lato",
    },
    whiteText: {
      color: COLORS.white,
    },
    sectionTitle: {
      fontSize: FONT_SIZES.large,
      fontWeight: "bold",
      color: color || COLORS.gray,
      borderBottomWidth: 1,
      borderBottomColor: color || COLORS.gray,
      marginBottom: MARGINS.small,
    },
    sectionInfo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "start",
      marginBottom: MARGINS.small,
      fontSize: FONT_SIZES.medium,
    },
    sectionSubtitle: {
      fontSize: FONT_SIZES.medium,
      fontWeight: "bold",
    },
    sectionEst: {
      color: color || COLORS.gray,
    },
    primaryText: {
      color: color || COLORS.gray,
    },
    header: {
      fontSize: FONT_SIZES.extraLarge,
      fontWeight: "bold",
      width: "100%",
      fontFamily: fontFamily || "Lato",
    },
    userTitle: {
      fontSize: FONT_SIZES.large,
      fontWeight: "bold",
      width: "100%",
      marginBottom: MARGINS.small,
      fontFamily: fontFamily || "Lato",
      color: color || COLORS.gray,
    },
    title: {
      fontSize: FONT_SIZES.large,
      fontWeight: "bold",
      color: COLORS.darkGray,
      marginBottom: MARGINS.small,
      fontFamily: fontFamily || "Lato",
    },
    subtitle: {
      fontSize: FONT_SIZES.medium,
      fontWeight: "bold",
      color: COLORS.gray,
      marginBottom: MARGINS.small,
      fontFamily: fontFamily || "Lato",
    },
    text: {
      fontSize: FONT_SIZES.small,
      marginBottom: MARGINS.small,
      color: COLORS.darkGray,
      fontFamily: fontFamily || "Lato",
    },
    skills: {
      flexDirection: "row",
      flexWrap: "wrap",
      fontFamily: fontFamily || "Lato",
    },
    skill: {
      fontSize: FONT_SIZES.small,
      fontWeight: "bold",
      color: COLORS.darkGray,
      fontFamily: fontFamily || "Lato",
    },
    score: {
      justifyContent: "end",
    },
    section: {
      padding: MARGINS.medium,
      flexGrow: 1,
      fontFamily: fontFamily || "Lato",
    },
    contentWrapper: {
      fontFamily: fontFamily || "Lato",
    },
    gridContainer: {
      display: "flex",
      flexDirection: "row",
      flexBasis: "100%",
      flexWrap: "wrap",
    },
    gridItem: {
      flexGrow: 1,
      flexBasis: "50%",
      padding: MARGINS.medium,
    },
    page: {
      paddingTop: 28,
      paddingHorizontal: 30,
    },
  });
}
