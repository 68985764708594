import { useApi } from "../../../hooks/useApi";
import { Fragment, useState } from "react";

export default function Template(props) {
  const { ref } = props.renderer;
  const [templates, isLoading, loaded, error] = useApi("templates/fetch");
  const [state, setTemplate] = useState({ templateId: null });

  const { templateId } = state;

  let templateRef = ref.issue("templateId");

  const handleCheck = (e) => {
    let tempId = e.target.attributes.refValue.value;
    console.log(tempId);
    setTemplate({ templateId: tempId });
  };

  return (
    <Fragment>
      <div class="titlecls">Choose your template!</div>
      <div className="container mt-4">
        <div className="row">
          {loaded
            ? templates.map((template, i) => {
                return (
                  <div className="col-md-6">
                    <div
                      className={
                        templateId != template.template_uid
                          ? "card border-dark"
                          : "card border border-success"
                      }
                      style={{ maxWidth: "300px" }}
                    >
                      {/* Image */}
                      <div className="p-2">
                        <h3 className="h4">{template.name}</h3>
                        <img
                          onClick={handleCheck}
                          role="button"
                          refValue={template.template_uid}
                          alt="..."
                          src={template.screenshot}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
          <input value={templateId} hidden ref={templateRef} type="text" />
        </div>
      </div>
    </Fragment>
  );
}
