import * as yup from "yup";
const educationSchema = yup.array().of(
  yup.object().shape({
    school: yup.string().required("School is required"),
    title: yup.string().required("Title is required"),
    start_year: yup.string().required("Start year is required"),
    start_month: yup.string().required("Start month is required"),
    end_year: yup.string().required("End year is required"),
    end_month: yup.string().required("End month is required"),
  })
);
const experienceSchema = yup.array().of(
  yup.object().shape({
    employer: yup.string().required("Employer field is required"),
    title: yup.string().required("Title is required"),
    start_year: yup.string().required("Start year is required"),
    start_month: yup.string().required("Start month is required"),
    end_year: yup.string().required("End year is required"),
    end_month: yup.string().required("End month is required"),
  })
);
const userSchema = yup.object().shape({
  id: yup.number(),
  firstname: yup.string().min(2).max(30).required(),
  lastname: yup.string().min(2).max(30).required(),
  username: yup.string().min(2).max(30).notRequired(),
  phone: yup
    .string()
    .min(10)
    .matches(/^[0-9]+$/)
    .notRequired(),
  email: yup.string().email().required(),
  address: yup.string().notRequired(),
  city: yup.string().notRequired(),
  country: yup.string().notRequired(),
  zip_code: yup.string().notRequired(),
  image_consent: yup.boolean().notRequired(),
  visibility_consent: yup.boolean().notRequired(),
  marketing_consent: yup.boolean().notRequired(),
  profile_pic: yup.string().notRequired(),
});

const skillsSchema = yup.array().of(
  yup.object().shape({
    title: yup.string().required("Skill name is required"),
    level: yup.string().required("Level is required"),
  })
);
const interestsSchema = yup.array().of(
  yup.object().shape({
    title: yup.string().required("Interest Name is required"),
  })
);
const snapshotSchema = yup.object().shape({
  education: educationSchema,
  experiences: experienceSchema,
  skills: skillsSchema,
  interests: interestsSchema,
  // professional_references: professionalReferencesSchema,
  user: userSchema,
  title: yup.string().required("Title is required"),
});
export { snapshotSchema, educationSchema, experienceSchema, skillsSchema };
