import { Formik, Form, Field, ErrorMessage } from "formik";
import { Fragment, useEffect, useState, createRef } from "react";
import * as Yup from "yup";
import { useForm } from "../../../hooks/useForm";
import { BsGoogle, BsMicrosoft } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import {
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, providerGoogle, providerMicrosoft } from "../../../firebase";

const mappedErrors = {
  "auth/id-token-expired": "Your auth was expired.",
  "auth/wrong-password": "Invalid email or password",
  "auth/email-already-exists": "Email already exists.",
  "auth/email-already-in-use": "Email already exists.",
  "auth/internal-error": "Server error, please try later...",
  "auth/invalid-email": "Invalid email",
  "auth/user-disabled": "User is suspended.",
};

export default function Account(props) {
  const { data: initialData, eventEmitter, next } = props;
  const { ref, formRef } = props.renderer;

  const [data, setData] = useState(initialData);
  const [, , , , request] = useForm();
  const [{ isNew }, setNew] = useState({});
  const [{ provider }, setProvider] = useState({ provider: providerGoogle });
  const [error, setError] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [passwordFormRef] = useState(createRef());

  const email = data.biography?.ContactData?.email;

  function setEmailStatus(data) {
    setData(data);
    let email;
    if ((email = data.biography?.ContactData?.email)) {
      request("/user/check", { email }).then((result) => {
        setNew({ isNew: result?.ok });
      });
    }
  }

  useEffect(() => {
    eventEmitter.on("account", setEmailStatus);
  });

  const validationSchema = Yup.object({
    email: Yup.string().required(),
    password: Yup.string().required(),
    isNew: Yup.boolean().default(isNew),
    confirmPassword: Yup.string().when("isNew", {
      is: true,
      then: (sc) => sc.required().oneOf([Yup.ref("password")]),
    }),
  });

  const gotToken = (token) => {
    window.localStorage.setItem("Authorization", token);
    return next();
  };

  const signInOrSignup = async (e) => {
    e.preventDefault();
    try {
      let authToken,
        password = ref.issue("password").current.value;
      if (isNew) {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const idToken = await userCredential.user.getIdToken();
        const response = await request("/user/register", {
          idToken,
          email,
          password,
          lastname: data.biography?.FullName?.lastName,
          firstname: data.biography?.FullName?.firstName,
        });

        authToken = response.token;
      } else {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );

        const idToken = await userCredential.user.getIdToken();

        let authData = await request("/user/login", {
          email,
          idToken,
          password,
        });

        authToken = authData.token;
      }

      await gotToken(authToken);
    } catch (error) {
      setError(mappedErrors[error.code] || `Error occured`);
    }
  };

  const handleExternalAuthentication = async (e) => {
    e.preventDefault();
    try {
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
      let authToken;
      // sign up
      if (isNew) {
        const response = await request("/user/register", {
          idToken,
          email: result.user.email,
          password: "",
          lastname: result.user.displayName.split(" ")[1],
          firstname: result.user.displayName.split(" ")[0],
          profile_pic: result.user.photoURL,
        });

        authToken = response.token;
      }

      if (!authToken) {
        // sign in
        let authData = await request("/user/login", {
          email: result.user.email,
          idToken,
        });

        authToken = authData.token;
      }

      await gotToken(authToken);
    } catch (error) {
      setError(mappedErrors[error.code] || `Error occured`);
    }
  };

  const startExternalAuth = (e, provider) => {
    e.preventDefault();
    setProvider({ provider });
    return handleExternalAuthentication(e);
  };

  const passwordAuth = (e) => {
    e.preventDefault();
    let form = formRef.current;
    if (form && !form.isValid) {
      setError("Please check your inputs!");
    } else {
      setError(false);
      return signInOrSignup(e);
    }
  };

  // if existing member popup signin

  // new member popup signup

  let title = isNew
    ? "You don't yet have a CVGenius Account."
    : "Seems like you already have an Account!.";

  let description = isNew
    ? "You are one tab closer to create your account and download/scan your resume!"
    : " Sign in to your account to download and scan your resume!";

  let btnTxt = isNew ? "Sign up" : "Sign in";

  let thirdPartiesTxt = isNew ? "Or sign up with" : "Or sign in with";

  let otherTxt = isNew ? " Aready have an account?" : "Create an account?";

  return (
    <Fragment>
      <div class="titlecls">{title}</div>
      <span class="descriptioncls">{description}</span>
      <div class="row justify-content-md-center mt-3">
        {error ? (
          <div class="mt-2 mb-2 alert alert-danger text-danger initialism">
            {error}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="col-sm-12 mt-6">
        <Formik
          innerRef={formRef}
          validationSchema={validationSchema}
          validateOnChange={true}
          initialValues={{ email, password: null, confirmPassword: null }}
          enableReinitialize={true}
        >
          {({ values }) => (
            <Form>
              <Field as="input" name="email">
                {({ field, form: { errors } }) => (
                  <Fragment>
                    <input
                      id="email"
                      autoComplete="false"
                      className={`start-modal-input  ${
                        errors.email ? "border-danger" : ""
                      }`}
                      type="text"
                      ref={ref.issue("email")}
                      {...field}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="mt-2 mb-2 text-danger initialism"
                    />
                  </Fragment>
                )}
              </Field>
              <Field as="input" name="password">
                {({ field, form: { errors } }) => (
                  <Fragment>
                    <input
                      id="password"
                      autoComplete="false"
                      ref={ref.issue("password")}
                      className={`start-modal-input ${
                        errors.password ? "border-danger" : ""
                      }`}
                      type="password"
                      placeholder="My password"
                      {...field}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="mt-2 mb-2 text-danger initialism"
                    />
                  </Fragment>
                )}
              </Field>
              {!isNew ? (
                ""
              ) : (
                <Field as="input" name="confirmPassword">
                  {({ field, form: { errors } }) => (
                    <Fragment>
                      <input
                        id="confirmPassword"
                        autoComplete="false"
                        ref={ref.issue("confirmPassword")}
                        className={`start-modal-input ${
                          errors.confirmPassword ? "border-danger" : ""
                        }`}
                        type="password"
                        placeholder="My confirmPassword"
                        {...field}
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="mt-2 mb-2 text-danger initialism"
                      />
                    </Fragment>
                  )}
                </Field>
              )}
            </Form>
          )}
        </Formik>
        <div class="row justify-content-md-center mb-6">
          <button
            onClick={passwordAuth}
            style={{ fontSize: "16px", fontWeight: 500, width: "25%" }}
            class="btx"
          >
            {btnTxt}
          </button>
        </div>
        <div class="mb-4">
          {isNew ? (
            ""
          ) : (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowForgetPassword(true);
              }}
              style={{
                color: "black",
                marginTop: "12px",
                fontSize: "16px",
                fontWeight: 500,
                width: "25%",
              }}
            >
              Forgot Password?
            </a>
          )}
          &nbsp;&nbsp;
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setNew({ isNew: !isNew });
              setError(false);
            }}
            style={{
              color: "black",
              marginTop: "12px",
              fontSize: "16px",
              fontWeight: 500,
              width: "25%",
            }}
          >
            {otherTxt}
          </a>
        </div>
        <div class="spx mb-6">
          <span class="hx">{thirdPartiesTxt}</span>
        </div>
        <div class="row justify-content-md-center mb-6">
          <button
            onClick={(e) => startExternalAuth(e, providerGoogle)}
            className="btx w-40"
          >
            <BsGoogle />
            &nbsp; Google
          </button>
          &nbsp;&nbsp;
          <button
            onClick={(e) => startExternalAuth(e, providerMicrosoft)}
            className="btx w-40"
          >
            <BsMicrosoft />
            &nbsp; Microsoft
          </button>
        </div>
      </div>
      <Modal
        show={showForgetPassword}
        onHide={() => setShowForgetPassword(false)}
      >
        <Modal.Header className="fgx" closeButton>
          <Modal.Title>Forget password</Modal.Title>
        </Modal.Header>
        <Modal.Body className="fgx">
          <Formik
            innerRef={passwordFormRef}
            validationSchema={Yup.object({
              email: Yup.string().required(),
            })}
            validateOnChange={true}
            initialValues={{}}
            isInitialValid={false}
          >
            {({ values }) => (
              <Form>
                <Field name="email" value={values.email}>
                  {({ field, form: { errors } }) => (
                    <input
                      id="email"
                      autoComplete="false"
                      className={`start-modal-input  ${
                        errors.email ? "border-danger" : ""
                      }`}
                      type="text"
                      placeholder="Email"
                      {...field}
                    />
                  )}
                </Field>

                <div class="row justify-content-md-center mt-4">
                  <button
                    onClick={passwordAuth}
                    style={{ fontSize: "16px", fontWeight: 500, width: "25%" }}
                    class="btx"
                  >
                    Send
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}
