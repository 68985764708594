export default function StatsOne(props) {
  return (
    <div className="card">
      <div className="card-body">
        {/* Title */}
        <span className="d-block h6 text-sm font-semibold mb-2">
          {props.title}
        </span>
        {/* Details */}
        <div className="row align-items-center">
          <div className="col">
            <div className="row align-items-center no-gutters">
              <div className="col-auto">
                <span className="h5 me-2">{props.percentage}%</span>
              </div>
              <div className="col">
                <div className="progress progress-sm">
                  <div
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{ width: props.percentage + "%" }}
                    aria-valuenow={75}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto">
            <div className="icon text-warning">
              <i className="bi bi-kanban" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
