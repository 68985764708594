import React from "react";
import { useLocation, Navigate } from "react-router";
import { fetchProfile } from "../data/userData";
let AuthContext = React.createContext({});

export default function AuthMiddleware({ children, path }) {
  let location = useLocation();

  let token = localStorage.getItem("Authorization");

  if (!token) {
    return <Navigate to="/signin" state={{ from: location }} />;
  }

  return children;
}
