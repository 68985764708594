import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase"; // Import the Firebase configuration

const PasswordReset = () => {
  const [resetEmail, setResetEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setMessage("Password reset email sent. Please check your inbox.");
      setError("");
    } catch (error) {
      setError("Error sending password reset email. Please try again.");
      setMessage("");
      console.error("Error sending password reset email:", error);
    }
  };

  return (
    <Form onSubmit={handlePasswordReset}>
      <h2>Reset Password</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {message && <Alert variant="success">{message}</Alert>}
      <Form.Group controlId="resetEmail">
        <Form.Control
          type="email"
          placeholder="Email"
          value={resetEmail}
          onChange={(e) => setResetEmail(e.target.value)}
        />
      </Form.Group>
      <Button type="submit">Send Password Reset Email</Button>
    </Form>
  );
};

export default PasswordReset;
