import { Fragment, useState, useContext, useEffect } from "react";
import { useApi } from "../../hooks/useApi";
import TemplateList from "../../components/TemplatesList";
import { ResumeContext } from "../../contexts/resume";
import Header from "../../components/header";
import CreatorNav from "../../components/CreatorNav";
import Navbar from "../../containers/navbar";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
export default function Templates() {
  const [resume, dispatch] = useContext(ResumeContext);
  const [templates, isLoading, loaded, error] = useApi("templates/fetch");
  const [templateId, setTemplateId] = useState(false);
  function handleCheck(e) {
    setTemplateId(e.target.attributes.value.nodeValue);
  }
  useEffect(() => {
    if (templateId) {
      dispatch({ type: "SET_TEMPLATE", payload: templateId }, resume);
    }
  }, [templateId]);

  return (
    <Fragment>
      <CreatorNav />
      <Header title="Choose a Template for your resume" />
      <div className="container mt-4">
        <div className="row">
          {loaded
            ? (() => {
                return templates.map((template, i) => {
                  return (
                    <TemplateList
                      handleCheck={handleCheck}
                      key={i}
                      image={template.image}
                      name={template.name}
                      description={template.description}
                      uuid={template.template_uid}
                      selected={
                        templateId === template.template_uid ? true : false
                      }
                    />
                  );
                });
              })()
            : ""}
        </div>
      </div>
    </Fragment>
  );
}
