import { Formik, Form, Field, ErrorMessage } from "formik";
import { Fragment } from "react";
import * as Yup from "yup";

export default function ContactData(props) {
  const { ref, formRef } = props.renderer;

  const validationSchema = Yup.object({
    email: Yup.string().required().email(),
    phoneNumber: Yup.string().required().min(10, "enter a valid phone number"),
    website: Yup.string().url("not a valid url"),
    linkedin: Yup.string().url("not a valid url"),
  });

  return (
    <Fragment>
      <div class="titlecls">How recruiters can reach you?</div>
      <Formik
        isInitialValid={false}
        innerRef={formRef}
        validationSchema={validationSchema}
        validateOnChange={true}
        initialValues={{}}
      >
        {({ values }) => (
          <Form>
            <Field name="email" value={values.email}>
              {({ field, form: { errors } }) => (
                <div>
                  <input
                    id="email"
                    autoComplete="false"
                    ref={ref.issue("email")}
                    className={`start-modal-input ${
                      errors.email ? "border-danger" : ""
                    }`}
                    type="text"
                    placeholder="Email"
                    {...field}
                  />
                </div>
              )}
            </Field>
            <ErrorMessage
              name="email"
              component="div"
              className="mt-2 mb-2 text-danger initialism"
            />
            <Field name="phoneNumber" value={values.phoneNumber}>
              {({ field, form: { errors } }) => (
                <div>
                  <input
                    id="phoneNumber"
                    autoComplete="false"
                    ref={ref.issue("phoneNumber")}
                    className={`start-modal-input ${
                      errors.phoneNumber ? "border-danger" : ""
                    }`}
                    type="text"
                    placeholder="Phone"
                    {...field}
                  />
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className="mt-2 mb-2 text-danger initialism"
                  />
                </div>
              )}
            </Field>
            <Field name="website" value={values.website}>
              {({ field, form: { errors } }) => (
                <div>
                  <input
                    id="website"
                    autoComplete="false"
                    ref={ref.issue("website")}
                    className={`start-modal-input ${
                      errors.website ? "border-danger" : ""
                    }`}
                    type="text"
                    placeholder="website"
                    {...field}
                  />
                  <ErrorMessage
                    name="website"
                    component="div"
                    className="mt-2 mb-2 text-danger initialism"
                  />
                </div>
              )}
            </Field>
            <Field name="linkedin" value={values.linkedin}>
              {({ field, form: { errors } }) => (
                <div>
                  <input
                    id="linkedin"
                    autoComplete="false"
                    ref={ref.issue("linkedin")}
                    className={`start-modal-input ${
                      errors.linkedin ? "border-danger" : ""
                    }`}
                    type="text"
                    placeholder="https://www.linkedin.com/in/"
                    {...field}
                  />
                  <ErrorMessage
                    name="linkedin"
                    component="div"
                    className="mt-2 mb-2 text-danger initialism"
                  />
                </div>
              )}
            </Field>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}
