import React from "react";
import { authReducer } from "../../reducers";

const initialState = {
  user: null,
  invitations: {},
};
export const UserContext = React.createContext({
  initialState,
  dispatch: () => null,
});
export const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(authReducer, initialState);
  return (
    <UserContext.Provider value={[state, dispatch]}>
      {children}
    </UserContext.Provider>
  );
};
