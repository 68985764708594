import React, { Component, createRef } from "react";
import BASIC from "../resumes/basic";
import { pdf } from "@react-pdf/renderer";
import * as pdfjsLib from "pdfjs-dist";
import { debounce } from "lodash";
import { ButtonGroup, Button } from "react-bootstrap";

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

class CreatorPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pdfDoc: null,
      isCanvasReady: false,
    };
    this.containerRef = createRef();
    this.canvasRef = createRef();
    this.updatePdfDoc = debounce(this.updatePdfDoc.bind(this), 800);
  }

  async componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.updatePdfDoc();
    }
  }

  async componentDidMount() {
    this.updatePdfDoc();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props !== nextProps ||
      this.state.pdfDoc !== nextState.pdfDoc ||
      this.state.isCanvasReady !== nextState.isCanvasReady
    );
  }

  async updatePdfDoc() {
    this.setState({ isLoading: true });
    const blob = await pdf(<BASIC {...this.props} />).toBlob();
    const url = URL.createObjectURL(blob);
    const doc = await pdfjsLib.getDocument(url).promise;
    this.setState({ pdfDoc: doc, isLoading: false, isCanvasReady: true });
  }

  render() {
    const { pdfDoc, isLoading, isCanvasReady } = this.state;
    const { fontFamily, primaryColor } = this.props;

    const renderPDFPage = async () => {
      if (!pdfDoc || !this.canvasRef.current) return;

      const pageNumber = 1;
      const page = await pdfDoc.getPage(pageNumber);

      let rotation = page.rotate || 0;
      if (rotation === 180) {
        rotation = 0;
      }

      const containerWidth = this.containerRef.current.clientWidth;
      const viewport = page.getViewport({ scale: 1, rotation });
      const scale = containerWidth / viewport.width;
      const scaledViewport = page.getViewport({ scale, rotation });

      const canvas = this.canvasRef.current;
      canvas.width = scaledViewport.width;
      canvas.height = scaledViewport.height;

      const canvasContext = canvas.getContext("2d");
      await page.render({
        canvasContext,
        viewport: scaledViewport,
        renderInteractiveForms: false,
        fontFamily: fontFamily,
      }).promise;

      this.setState({ isCanvasReady: false });
    };

    // Render the PDF page if the canvas is ready
    if (isCanvasReady) {
      renderPDFPage();
    }

    return (
      <div
        className=""
        style={{
          height: "90vh",
          width: "100%",
          marginTop: "4vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div className="container-fluid py-1 h-full">
          <div className="border border-2 bg-surface-white h-full border-dashed rounded d-flex w-100">
            <div
              className="font-semibold text-muted background-white d-flex justify-content-center w-100 mt-2"
              ref={this.containerRef}
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              {isLoading && <div>Loading...</div>}
              {!isLoading && <canvas ref={this.canvasRef} />}
              <ButtonGroup
                aria-label="Basic example"
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  width: "100%",
                  height: "50px",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  color: "white",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  borderRadius: "0",
                  zIndex: "9000",
                }}
              >
                <Button variant="secondary">Left</Button>
                <Button variant="secondary">Middle</Button>
                <Button variant="secondary">Right</Button>
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatorPreview;
