import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { GlobalOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function LanguageSelector() {
  const { t, i18n } = useTranslation();

  function changeLanguage(value) {
    localStorage.setItem("language", value);
    i18n.changeLanguage(value);
  }

  const browserLang = navigator.language.split("-")[0];

  return (
    <Select
      defaultValue={
        i18n.language || localStorage.getItem("language") || browserLang
      }
      onChange={changeLanguage}
      // style={{ width: 120 }}
      dropdownAlign={{ offset: [0, 8] }}
      dropdownMatchSelectWidth={false}
      suffixIcon={<GlobalOutlined />}
    >
      <Option value="en">
        <span role="img" aria-label={t("english")}>
          🇺🇸
        </span>{" "}
        EN
      </Option>
      <Option value="fr">
        <span role="img" aria-label={t("french")}>
          🇫🇷
        </span>{" "}
        FR
      </Option>
    </Select>
  );
}
