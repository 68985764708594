import { FontColorsOutlined } from "@ant-design/icons";
const fonts = [
  {
    family: "Roboto",
    src: "https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxP.ttf",
  },
  {
    family: "Lateef",
    src: "https://fonts.gstatic.com/s/lateef/v10/HI_EiYEVKqRMq0jBSZXAQ4-d.ttf",
  },
  {
    family: "Noto Sans",
    src: "https://fonts.gstatic.com/s/notosans/v15/o-0IIpQlx3QUlC5A4PNr5TRGvQ.ttf",
  },
  {
    family: "Montserrat",
    src: "https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf",
  },
  {
    family: "Lato",
    src: "https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjxAwXjeu.woff2",
  },
  {
    family: "Open Sans",
    src: "https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.ttf",
  },
  {
    family: "Roboto Condensed",
    src: "https://fonts.gstatic.com/s/robotocondensed/v19/ieVl2ZhbGCW-JoW6S34pSDpqYKU059WxDCs5cvI.ttf",
  },
  {
    family: "Raleway",
    src: "https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0IT4ttDfCmxA.ttf",
  },
  {
    family: "Oswald",
    src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  },
  {
    family: "Lora",
    src: "https://fonts.gstatic.com/s/lora/v13/0QIvMX1D_JOuGQbT0gvT.ttf",
  },
  {
    family: "Playfair Display",
    src: "https://fonts.gstatic.com/s/playfairdisplay/v15/nuFiD-vYSZviVYUb_rj3ij__anPXDTzYgQ.ttf",
  },
  {
    family: "Merriweather",
    src: "https://fonts.gstatic.com/s/merriweather/v19/u-4n0qyriQwlOrhSvowK_l521wRpX837pvjxPA.ttf",
  },
  {
    family: "PT Sans",
    src: "https://fonts.gstatic.com/s/ptsans/v11/jizaRExUiTo99u79D0aExdGM.ttf",
  },
  {
    family: "PT Serif",
    src: "https://fonts.gstatic.com/s/ptserif/v11/EJRVQgYoZZY2vCFuvAFWzr8.ttf",
  },
  {
    family: "Noto Serif",
    src: "https://fonts.gstatic.com/s/notoserif/v8/ga6Iaw1J5X9T9RW6j9bNTFA.ttf",
  },
  {
    family: "Source Sans Pro",
    src: "https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7lujVj9w.ttf",
  },
];

const FontDropdown = ({ font, setFont }) => {
  const fontOptions = fonts.map((font) => ({
    label: font.family,
    value: font.family,
  }));

  return (
    <div className="dropdown">
      <button
        className="btn btn-white dropdown-toggle"
        type="button"
        id="font-dropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <FontColorsOutlined />
        <span
          style={{
            fontFamily: font,
          }}
        >
          {font}
        </span>
      </button>
      <ul className="dropdown-menu" aria-labelledby="font-dropdown">
        {fontOptions.map((option, index) => (
          <li key={index}>
            <button
              className="dropdown-item"
              type="button"
              onClick={() => {
                setFont(option.value);
              }}
              value={font}
            >
              <p
                style={{
                  fontFamily: option.value,
                }}
              >
                {option.label}
              </p>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { fonts, FontDropdown };
