import { Fragment, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { ResumeContext } from "../../contexts/resume";
import { useApi } from "../../hooks/useApi";
import Navbar from "../../containers/navbar";

import CreatorControl from "../../components/CreatorSide";
import CreatorPreview from "../../components/CreatorPreview";
import { Nav } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import constants from "../../constants/api";
import { Spin } from "antd";

export default function Resume() {
  const [query] = useSearchParams();
  const [resume, isLoading, loaded, error] = useApi(
    query.get("id") ? "resume/fetch?id=" + query.get("id") : "resume/fetch"
  );

  const [render, setResume] = useState(null);
  useEffect(() => {
    if (loaded) {
      setResume({
        ...resume.context,
        title: resume.title,
        template: resume.template,
        uid: resume.resume_uid,
      });
    }
  }, [resume, loaded]);

  return (
    <Fragment>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div>
          {render && <CreatorControl rid={query.get("id")} resume={render} />}
        </div>
      )}
    </Fragment>
  );
}
