import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineUser } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles
function PersonalInfo({ data, handler }) {
  const [showEmailPhone, setShowEmailPhone] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="p-1 step-1">
      <div className="row">
        <div className="col-md-12">
          <div className="">
            <label className="form-label" htmlFor="address">
              {t("t_address")}:
            </label>
            <input
              type="text"
              value={data?.address}
              onChange={handler}
              className="form-control"
              id="address"
              data-type="user"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-12">
          <div className="row">
            <div className="col-md-6">
              <div className="">
                <label className="form-label" htmlFor="firstname">
                  Linkedin:
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handler}
                  value={data?.linkedin}
                  data-type="user"
                  id="linkedin"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="">
                <label className="form-label" htmlFor="lastname">
                  {t("website")}:
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handler}
                  value={data?.website}
                  data-type="user"
                  id="website"
                />
              </div>
            </div>

            <div className="col-md-6 d-none d-sm-block">
              <div className="">
                <label className="form-label" htmlFor="email">
                  Twitter:
                </label>
                <input
                  type="email"
                  value={data?.twitter}
                  onChange={handler}
                  className="form-control"
                  id="twitter"
                  data-type="user"
                />
              </div>
            </div>
            <div className="col-md-6 d-none d-sm-block">
              <div className="">
                <label className="form-label" htmlFor="phone">
                  Github:
                </label>
                <input
                  type="text"
                  value={data?.phone}
                  onChange={handler}
                  className="form-control"
                  data-type="github"
                  id="phone"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="">
            <label className="form-label" htmlFor="bio">
              {t("about_you")}:
            </label>
            <ReactQuill
              theme="snow"
              value={data?.bio}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                ],
              }}
              rows={5}
              onChange={(content, delta, source, editor) => {
                handler({
                  target: {
                    id: "bio",
                    value: content,
                    dataset: {
                      type: "user",
                    },
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
