import moment from "moment";
import Languages from "../../components/Languages/index";
const data = {
  API_BASE_URL: "https://api.cvgenius.ai",
  APP_BASE_URL: "https://app.cvgenius.ai",
  RESUME: {
    education: [
      {
        school: "",
        title: "",
        location: "",
        start_year: moment().format("YYYY"),
        start_month: moment().format("MMMM"),
        end_year: moment().format("YYYY"),
        end_month: moment().format("MMMM"),
        description: "",
      },
    ],
    experiences: [
      {
        employer: "",
        title: "",
        location: "",
        start_year: moment().format("YYYY"),
        start_month: moment().format("MMMM"),
        end_year: moment().format("YYYY"),
        end_month: moment().format("MMMM"),
        description: "",
      },
    ],
    skills: [
      {
        title: "",
        level: 0,
      },
    ],
    languages: [
      {
        title: "",
        level: 0,
      },
    ],
    interests: [
      {
        title: "",
      },
    ],
    user: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      address: "",
      bio: "",
      title: "",
      website: "",
      twitter: "",
      linkedin: "",
      github: "",
      birthdate: moment().format("YYYY-MM-DD"),
    },
  },
};
export default data;
