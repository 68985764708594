// ButtonGroup.js
import React from "react";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";
import { BgColorsOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { FontDropdown } from "../../resumes/fonts";
import ColorPicker from "./ColorPicker";

const RenderButtons = ({ font, setFont, color, setColor, downloadPdf }) => {
  const { t } = useTranslation();

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };

  return (
    <div
      className="btn-group btn-white"
      role="group"
      aria-label="Basic example"
    >
      <FontDropdown font={font} setFont={setFont} />
      <OverlayTrigger
        trigger="click"
        placement="top"
        overlay={
          <Popover id="color-picker">
            <Popover.Body>
              <ColorPicker color={color} onChange={handleColorChange} />
            </Popover.Body>
          </Popover>
        }
      >
        <Button variant="white">
          <BgColorsOutlined />
        </Button>
      </OverlayTrigger>
      <Button variant="white" onClick={downloadPdf}>
        {t("download")}
      </Button>
    </div>
  );
};

export default RenderButtons;
