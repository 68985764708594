function Item(props) {
  const { item } = props;
  return (
    <div className="list-group-item d-flex align-items-center px-2 shadow-1 border rounded mb-1">
      <div>
        <a href="#" className="avatar rounded-circle">
          <img alt="..." src={item.image} />
        </a>
      </div>
      <div className="flex-fill ms-4">
        {/* Title */}
        <a href="#" className="d-block h6 mb-1">
          {item.email}
        </a>
        {/* Status */}
        <div>
          <span className="badge badge-lg badge-dot text-xs text-muted">
            <i className={item.accepted ? "bg-success" : "bg-danger"} />
            {item.accepted ? "Aaccepted" : "Pending"}
          </span>
        </div>
      </div>
      <div>
        <a
          href="#"
          onClick={() => {
            navigator.clipboard.writeText(item.url);
          }}
          className="btn btn-sm btn-neutral px-4 py-2 text-xs"
        >
          Copy
        </a>
      </div>
    </div>
  );
}

export default function ListGroup(props) {
  const { list } = props;

  // const items = list.forEach(item => <Item item={item}/>

  return (
    <div className="list-group list-group-flush  mt-4">
      {list.map((item, i) => {
        return <Item item={item} key={i}></Item>;
      })}
    </div>
  );
}
