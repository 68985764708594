import { object, string } from "yup";
const profileSchema = object({});

const addInvitationSchema = object({
  email: string().email().required().min(8),
});

export default {
  addInvitationSchema,
};
