import { login } from "../data/userData";
import axios from "axios";

function SignIn(state, payload) {
  return {
    ...state,
    user: payload,
  };
}
function addInvite(state, payload) {
  return {
    ...state,
    invitations: payload,
  };
}

function logout(state, payload) {
  return { state };
}

export default function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN_ACTION":
      return SignIn(state, action.payload);
    case "LOGOUT_ACTION":
      return logout(state);
    case "ADD_INVITATION":
      return addInvite(state, action.payload);
    default:
      throw new Error("no action found!");
  }
}
