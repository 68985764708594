import React, { useState, useEffect } from "react";
import { Font, Page, Text, View, Document, Image } from "@react-pdf/renderer";

import createStyles from "./styles";
import { useTranslation } from "react-i18next";
import { fonts } from "../fonts";
import { TextIcon } from "../views/IconText";
import { Geometry } from "../views/Geometry";
import { Education } from "../views/Education";
import { LinkIcon } from "../views/IconLink";
import { Experience } from "../views/Experience";
import { Score } from "../views/Score";
import Languages from "../../components/Languages/index";
const Resume = ({
  user = {},
  education = [],
  experiences = [],
  skills = [],
  languages = [],
  interests = [],
  fontFamily = "Roboto",
  primaryColor = "#000",
}) => {
  const { t } = useTranslation();
  const font = fonts.find((font) => font.family === fontFamily);
  Font.register(font);
  const styles = createStyles(fontFamily, primaryColor);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Geometry color={primaryColor} />
        <View style={[styles.section, styles.container]}>
          <View>
            <Text style={[styles.header]}>
              {user.lastname} {user.firstname}
            </Text>
            <Text style={[styles.userTitle]}>{user.title}</Text>
            <View style={styles.sectionInfo}>
              <TextIcon
                iconName="phone"
                style={[styles.text]}
                size={12}
                text={user.phone}
              />

              <TextIcon
                iconName="atSymbol"
                style={[styles.text]}
                size={12}
                text={user.email}
              />
            </View>
            <TextIcon
              iconName="location"
              size={12}
              style={[styles.text]}
              text={user.address}
            />
          </View>
          <View style={styles.imageWrapper}>
            {user.profile_pic && (
              <Image src={user.profile_pic} style={styles.image} />
            )}
          </View>
        </View>
        <View style={[styles.contentWrapper, styles.gridContainer]}>
          <Education education={education} styles={styles} />

          <Experience experience={experiences} styles={styles} />

          <View style={[styles.section, styles.gridItem]}>
            <Text style={styles.sectionTitle}>{t("t_skills")}</Text>

            <View style={styles.skills}>
              {skills.map((s) => (
                <View style={styles.container}>
                  <Text style={styles.skill} key={s.id}>
                    {s.title}
                  </Text>
                  <Score
                    rank={s.level}
                    style={styles.score}
                    primaryColor={primaryColor}
                    lightColor={"#ddd"}
                  />
                </View>
              ))}
            </View>
          </View>
          <View style={[styles.section, styles.gridItem]}>
            <Text style={styles.sectionTitle}>{t("languages")}</Text>

            <View style={styles.languages}>
              {languages.map((s) => (
                <View style={styles.container}>
                  <Text style={styles.skill} key={s.id}>
                    {s.title}
                  </Text>
                  <Score
                    rank={s.level}
                    style={styles.score}
                    primaryColor={primaryColor}
                    lightColor={"#ddd"}
                  />
                </View>
              ))}
            </View>
          </View>
          <View style={[styles.section, styles.gridItem]}>
            <Text style={styles.sectionTitle}>{t("t_interests")}</Text>

            {interests.map((int) => (
              <Text key={int.id} style={styles.text}>
                {int.title}
              </Text>
            ))}
          </View>
          <View style={[styles.section, styles.gridItem]}>
            <Text style={styles.sectionTitle}>Links</Text>
            <LinkIcon
              iconName="linkedin"
              style={[styles.text]}
              size={12}
              url={user.linkedin}
            />
            <LinkIcon
              iconName="website"
              style={[styles.text]}
              size={12}
              url={user.website}
            />
            <LinkIcon
              iconName="twitter"
              style={[styles.text]}
              size={12}
              url={user.twitter}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Resume;
